import React, { useEffect, useRef, useState } from 'react';
import { styled, keyframes } from '@mui/material/styles';
import {
  Box,
  Button as MuiButton,
  Typography,
  Fade,
  CircularProgress,
  Stack,
} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

// Importa la librería de Vapi
import Vapi from '@vapi-ai/web';
import { assistantOptions } from './AssistantOptions';

// Utilidad para detectar error de public key (la misma que tienes en tu proyecto)
function isPublicKeyMissingError({ vapiError }: { vapiError: any }) {
  if (!vapiError || !vapiError.message) return false;
  return (
    vapiError.message.includes('public key') ||
    vapiError.message.includes('invalid public key')
  );
}

// Hook para manejar mensaje de "Falta Public Key"
function usePublicKeyInvalid() {
  const [showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage] = useState(false);

  useEffect(() => {
    if (showPublicKeyInvalidMessage) {
      const timer = setTimeout(() => {
        setShowPublicKeyInvalidMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showPublicKeyInvalidMessage]);

  return {
    showPublicKeyInvalidMessage,
    setShowPublicKeyInvalidMessage,
  };
}

// ========== Estilos con MUI & styled() ==========

// Animación de pulso
const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(74,58,255,0.4), 0 0 20px rgba(74,58,255,0.1);
  }
  50% {
    transform: scale(1.08);
    box-shadow: 0 0 15px rgba(74,58,255,0.6), 0 0 30px rgba(74,58,255,0.2);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(74,58,255,0.4), 0 0 20px rgba(74,58,255,0.1);
  }
`;

// Contenedor principal
const RootContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

// El botón principal “Give it a try!”
const CircularButton = styled(MuiButton)<{ $pulsating?: boolean }>(
  ({ $pulsating }) => ({
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '50%',
    padding: '1.4rem 1.4rem',
    minWidth: '64px',
    border: '2px solid rgba(255,255,255,0.3)',
    backdropFilter: 'blur(8px)',
    background:
      'radial-gradient(circle at 30% 30%, rgba(255,255,255,0.15), rgba(255,255,255,0))',
    color: '#fff',
    transition: 'all 0.3s ease',
    fontWeight: 500,
    boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
    '&:hover': {
      background:
        'radial-gradient(circle at 30% 30%, rgba(255,255,255,0.25), rgba(255,255,255,0))',
      transform: 'scale(1.05)',
      boxShadow: '0 6px 16px rgba(0,0,0,0.4)',
    },
    // Si $pulsating es true, aplica la animación de pulso
    animation: $pulsating
      ? `${pulseAnimation} 1.8s infinite ease-in-out`
      : 'none',
  })
);

// El círculo “estado” interno (icono)
const MicCircle = styled(Box)(() => ({
  width: '7.8rem',
  height: '7.8rem',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background:
    'linear-gradient(135deg, rgba(74,58,255,0.3), rgba(74,58,255,0.1) 60%, rgba(74,58,255,0.05))',
  transition: 'all 0.3s ease',
}));

// Botón de terminar llamada
const EndCallButton = styled(MuiButton)(() => ({
  marginTop: '1rem',
  borderRadius: '100px',
  color: '#ff7676',
  backgroundColor: 'rgba(255,255,255,0.06)',
  border: '1px solid rgba(255,255,255,0.2)',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.12)',
    borderColor: 'rgba(255,255,255,0.4)',
    transform: 'scale(1.02)',
  },
}));

// Mensaje flotante cuando falta la Public Key
const PublicKeyMessage = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: '25px',
  left: '25px',
  padding: '10px 15px',
  color: '#fff',
  backgroundColor: '#f03e3e',
  borderRadius: '5px',
  boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
  zIndex: 9999,
  ...theme.typography.body2,
}));

// ========== COMPONENTE PRINCIPAL ==========
const Voice: React.FC = () => {
  // Instanciamos Vapi con la Public Key (REEMPLAZA con tu key real)
  const vapiRef = useRef(new Vapi("926691e9-8813-4793-a53b-7a7e7996cbe7"));
  const vapi = vapiRef.current;

  // Estados
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [assistantIsSpeaking, setAssistantIsSpeaking] = useState(false);
  // const [volumeLevel, setVolumeLevel] = useState<number>(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('autoStart') === 'true') {
      startCall();
    }
  }, []);

  // Manejo del mensaje "Public Key Missing"
  const { showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage } =
    usePublicKeyInvalid();

  // Efecto para registrar listeners
  useEffect(() => {
    vapi.on('call-start', () => {
      setConnecting(false);
      setConnected(true);
      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on('call-end', () => {
      setConnecting(false);
      setConnected(false);
      setShowPublicKeyInvalidMessage(false);
      setAssistantIsSpeaking(false);
      // setVolumeLevel(0);
      window.location.href = '/waiting';
    });

    vapi.on('speech-start', () => {
      setAssistantIsSpeaking(true);
    });

    vapi.on('speech-end', () => {
      setAssistantIsSpeaking(false);
    });

    // vapi.on('volume-level', (level: number) => {
    //   // setVolumeLevel(level);
    // });

    vapi.on('error', (error: any) => {
      console.error('Vapi Error:', error);
      setConnecting(false);

      if (isPublicKeyMissingError({ vapiError: error })) {
        setShowPublicKeyInvalidMessage(true);
      }
    });

    // Limpieza de listeners al desmontar
    return () => {
      vapi.removeAllListeners();
    };
  }, [vapi, setShowPublicKeyInvalidMessage]);

  // Iniciar llamada
  const startCall = () => {
    setConnecting(true);
    // Ejemplo: si tienes un "assistantId" persistente
    vapi.start(assistantOptions);
  };

  // Terminar llamada
  const endCall = () => {
    try {
      vapi.stop();
      // Forzamos la limpieza de estado, por si el call-end no llega inmediatamente
      setTimeout(() => {
        setConnected(false);
        setConnecting(false);
        setAssistantIsSpeaking(false);
        // setVolumeLevel(0);
      }, 500);
    } catch (error) {
      console.error("Error al detener la llamada:", error);
    }
  };

  // ---------------------- UI LÓGICA ----------------------
  // Determina qué ícono o contenido mostrar en el botón circular
  const getButtonIcon = () => {
    if (connecting) {
      // Mostramos un spinner mientras conecta
      return <CircularProgress color="inherit" size={44} />;
    }
    if (connected) {
      // Si está conectado, en este caso seguimos mostrando el mic
      // (Podrías usar otro ícono si deseas algo distinto)
      return <MicIcon fontSize="large" />;
    }
    // Estado inicial (no conectado ni conectando)
    return <MicIcon fontSize="large" />;
  };

  // Determina si el círculo hace pulso (cuando la IA está hablando)
  const isPulsating = assistantIsSpeaking && connected;

  // Render principal
  return (
    <Stack justifyContent="center" alignItems="center" sx={{ height: '100vh', backgroundColor: '#121212', paddingBottom: '100px' }}>
      <RootContainer>
        {!connected ? (
          <Fade in={!connected}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={2}
            >
              <CircularButton
                onClick={startCall}
                disabled={connecting}
                disableRipple
                $pulsating={false}
              >
                <MicCircle>{getButtonIcon()}</MicCircle>
              </CircularButton>

              <Typography
                variant="body1"
                sx={{
                  color: '#ddd',
                  fontSize: '1rem',
                  mt: 1,
                  transition: 'color 0.3s ease',
                }}
              >
                {connecting ? '' : '¡Habla con un asistente!'}
              </Typography>
            </Box>
          </Fade>
        ) : (
          <Fade in={connected}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={2}
            >
              {/* Botón circular "activo" */}
              <CircularButton
                onClick={() => { /* Podrías hacer algo al pulsar el botón estando en llamada */ }}
                disableRipple
                $pulsating={isPulsating}
              >
                <MicCircle>{getButtonIcon()}</MicCircle>
              </CircularButton>

              {/* Indicador de volumen y estado */}
              {/* <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
              >
                <VolumeUpIcon sx={{ color: assistantIsSpeaking ? '#0ff' : '#aaa' }} />
                <Typography
                  variant="body2"
                  sx={{ color: '#fff', minWidth: '80px', textAlign: 'center' }}
                >
                  Vol: {volumeLevel.toFixed(2)}
                </Typography>
              </Box> */}

              {/* Estado “Hablando” */}
              {/* {assistantIsSpeaking && (
                <Typography variant="body2" sx={{ color: '#0ff' }}>
                  El asistente está hablando...
                </Typography>
              )} */}

              {/* Botón de terminar llamada */}
              <EndCallButton variant="contained" onClick={endCall}>
                <CancelPresentationIcon sx={{ mr: 1, borderRadius: '100px' }} />
                Terminar Llamada
              </EndCallButton>
            </Box>
          </Fade>
        )}

        {/* Mensaje si la public key es inválida */}
        {showPublicKeyInvalidMessage && (
          <PublicKeyMessage>
            Is your Vapi Public Key missing? (recheck your code)
          </PublicKeyMessage>
        )}

      </RootContainer>
    </Stack>

  );
};

export default Voice;

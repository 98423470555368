import { useState, useContext, useEffect } from 'react';
import { Box, Button, Typography, Select, MenuItem, FormControl, InputLabel, CircularProgress, Grid } from '@mui/material';
import { ConfigContext } from '../../context/Config/ConfigContext';
import SectionContainer from '../../global/SectionContainer';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../components/Toast/toast';
import { auth, db } from '../../services/firebase/firebase';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { Publication } from '../../interfaces/Publication';
import { generatePublications } from './aiService';

function PublicationCreator() {
  const { t } = useTranslation('global');
  const { themeMode } = useContext(ConfigContext);
  const [numberOfPublications, setNumberOfPublications] = useState(5);
  const [templates, setTemplates] = useState<any[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [images, setImages] = useState<any[]>([]);
  const [selectedImageIds, setSelectedImageIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [generatedPublications, setGeneratedPublications] = useState<Publication[]>([]);

  useEffect(() => {
    const fetchTemplatesAndImages = async () => {
      try {
        const templatesQuery = query(collection(db, 'templates'), where('ownerId', '==', auth.currentUser?.uid));
        const templatesSnapshot = await getDocs(templatesQuery);
        const templatesData = templatesSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setTemplates(templatesData);

        const imagesQuery = query(collection(db, 'media'), where('ownerId', '==', auth.currentUser?.uid), where('type', '==', 'image'));
        const imagesSnapshot = await getDocs(imagesQuery);
        const imagesData = imagesSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setImages(imagesData);
      } catch (error) {
        console.error('Error fetching templates and images:', error);
      }
    };

    fetchTemplatesAndImages();
  }, []);

  const handleGeneratePublications = async () => {
    setLoading(true);
    try {
      const personalizationDoc = await getDocs(query(collection(db, 'personalization'), where('ownerId', '==', auth.currentUser?.uid)));
      const personalizationData = personalizationDoc.docs[0]?.data();

      const aiResponse = await generatePublications({
        numberOfPublications,
        templateId: selectedTemplateId,
        imageIds: selectedImageIds,
        personalizationData,
      });

      setGeneratedPublications(aiResponse);
      showToast('success', t('publicationCreator.publicationsGenerated'), themeMode);
    } catch (error) {
      console.error('Error generating publications:', error);
      showToast('error', t('publicationCreator.errorGeneratingPublications'), themeMode);
    } finally {
      setLoading(false);
    }
  };

  const handleSavePublications = async () => {
    setLoading(true);
    try {
      const publicationsRef = collection(db, 'publications');
      for (const pub of generatedPublications) {
        await addDoc(publicationsRef, {
          ...pub,
          ownerId: auth.currentUser?.uid,
          timestamp: new Date(),
        });
      }
      showToast('success', t('publicationCreator.publicationsSaved'), themeMode);
    } catch (error) {
      console.error('Error saving publications:', error);
      showToast('error', t('publicationCreator.errorSavingPublications'), themeMode);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SectionContainer
      title={t('publicationCreator.title') as string}
      text={t('publicationCreator.description') as string}
    >
      <Box sx={{ mt: 2 }}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel sx={{ color: themeMode === 'dark' ? '#fafafa' : '#181818' }}>
            {t('publicationCreator.numberOfPublications')}
          </InputLabel>
          <Select
            value={numberOfPublications}
            onChange={(e) => setNumberOfPublications(Number(e.target.value))}
            sx={{
              color: themeMode === 'dark' ? '#ffffff' : '#181818',
              bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
              '& .MuiSvgIcon-root': {
                color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
              },
              borderRadius: '13px',
            }}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
              <MenuItem key={num} value={num}>{num}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel sx={{ color: themeMode === 'dark' ? '#fafafa' : '#181818' }}>
            {t('publicationCreator.selectTemplate')}
          </InputLabel>
          <Select
            value={selectedTemplateId}
            onChange={(e) => setSelectedTemplateId(e.target.value)}
            sx={{
              color: themeMode === 'dark' ? '#ffffff' : '#181818',
              bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
              '& .MuiSvgIcon-root': {
                color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
              },
              borderRadius: '13px',
            }}
          >
            {templates.map((template) => (
              <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel sx={{ color: themeMode === 'dark' ? '#fafafa' : '#181818' }}>
            {t('publicationCreator.selectImages')}
          </InputLabel>
          <Select
            multiple
            value={selectedImageIds}
            onChange={(e) => setSelectedImageIds(e.target.value as string[])}
            sx={{
              color: themeMode === 'dark' ? '#ffffff' : '#181818',
              bgcolor: themeMode === 'dark' ? '#181818' : '#fafafa',
              '& .MuiSvgIcon-root': {
                color: themeMode === 'dark' ? '#ffffff' : '#4a3aff',
              },
              borderRadius: '13px',
            }}
            renderValue={(selected) => selected.length === 0 ? t('publicationCreator.noImagesSelected') : `${selected.length} ${t('publicationCreator.imagesSelected')}`}
          >
            {images.map((image) => (
              <MenuItem key={image.id} value={image.id}>
                {image.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          onClick={handleGeneratePublications}
          disabled={loading || !selectedTemplateId || selectedImageIds.length === 0}
          sx={{
            mt: 2,
            backgroundColor: '#4a3aff',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#3a2aff',
            },
          }}
        >
          {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : t('publicationCreator.generatePublications')}
        </Button>

        {generatedPublications.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ color: themeMode === 'dark' ? '#fafafa' : '#181818', mb: 2 }}>
              {t('publicationCreator.preview')}
            </Typography>
            <Grid container spacing={2}>
              {generatedPublications.map((pub, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box
                    sx={{
                      backgroundColor: themeMode === 'dark' ? '#181818' : '#fff',
                      borderRadius: '15px',
                      padding: '16px',
                      boxShadow: themeMode === 'dark'
                        ? '0 4px 6px rgba(0, 0, 0, 0.05)'
                        : '0 4px 6px rgba(0, 0, 0, 0.05)',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="h6" sx={{ color: themeMode === 'dark' ? '#fafafa' : '#181818' }}>
                      {pub.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: themeMode === 'dark' ? '#c1c1c1' : '#4a4a4a', mt: 1 }}>
                      {pub.insideDescription}
                    </Typography>
                    <Typography variant="body2" sx={{ color: themeMode === 'dark' ? '#c1c1c1' : '#4a4a4a', mt: 1 }}>
                      {pub.cta}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Button
              variant="contained"
              onClick={handleSavePublications}
              disabled={loading}
              sx={{
                mt: 4,
                backgroundColor: '#4a3aff',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#3a2aff',
                },
              }}
            >
              {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : t('publicationCreator.savePublications')}
            </Button>
          </Box>
        )}
      </Box>
    </SectionContainer>
  );
}

export default PublicationCreator;

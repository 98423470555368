import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../services/firebase/firebase';
import { Box, Button, CircularProgress, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import SectionContainer from '../../global/SectionContainer';
import { useContext } from 'react';
import { ConfigContext } from '../../context/Config/ConfigContext';
import 'react-toastify/dist/ReactToastify.css';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import DialogCommon from '../../components/DialogCommon/DialogCommon';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../components/Toast/toast';
import StoreContext from '../../context/Store/storeContext';

type Chatbot = {
  assistantFirstMessage: string;
  businessType: string;
  chatbotFormality: string;
  chatbotPersonality: string;
  chatbotTone: string;
  companyName: string;
  creationDate: string;
  defaultLanguage: string;
  logo: string;
  lightModeLogo: string;
  nightMode: boolean;
  primaryColor: string;
  responseTime: string;
  systemInstructions: string;
  useEmojis: boolean;
  chatbotState: string;
  responseCount: number,
  suggestion1: string,
  suggestion2: string,
  suggestion3: string,
  feedbackInstructions: string;
};

function IndividualChatbot() {
  const { id } = useParams();
  const [chatbot, setChatbot] = useState<Chatbot | null>(null);
  const [chatbotURL, setChatbotURL] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const { toggleChatbot } = useContext(StoreContext);

  const { t } = useTranslation('global')

  const [iframeCode, setIframeCode] = useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    updateSystemInstructions,
    updateAssistantFirstMessage,
    updatePrimaryColor,
    updateCompanyLogo,
    updateCompanyName,
    updateChatbotTone,
    updateChatbotFormality,
    updateDefaultLanguage,
    updateUseEmojis,
    updateCompanyLightModeLogo,
    updateResponseTime,
    updateChatbotPersonality,
    updateNightMode,
    updateBusinessType,
    updateChatbotState,
    updateResponseCount,
    updateSuggestion1,
    updateSuggestion2,
    updateSuggestion3,
    updateFeedbackInstructions
  } = useContext(ConfigContext);

  useEffect(() => {
    const fetchChatbot = async () => {

      try {
        if (!id) {
          showToast('error', t('individualChatbot.errors.chatbotIdNotProvided'), themeMode)
          return;
        }
        const chatbotDocRef = doc(db, 'chatbots', id);
        const chatbotDocSnapshot = await getDoc(chatbotDocRef);
        if (chatbotDocSnapshot.exists()) {
          const data = chatbotDocSnapshot.data() as Chatbot;

          // Actualizar el contexto y el estado local con la información del chatbot
          updateSystemInstructions(data.systemInstructions);
          updateAssistantFirstMessage(data.assistantFirstMessage);
          updatePrimaryColor(data.primaryColor);
          updateCompanyLogo(data.logo);
          updateCompanyLightModeLogo(data.lightModeLogo)
          updateCompanyName(data.companyName);
          updateChatbotTone(data.chatbotTone);
          updateChatbotFormality(data.chatbotFormality);
          updateDefaultLanguage(data.defaultLanguage);
          updateUseEmojis(data.useEmojis);
          updateResponseTime(data.responseTime);
          updateChatbotPersonality(data.chatbotPersonality);
          updateNightMode(data.nightMode);
          updateBusinessType(data.businessType);
          updateChatbotState(data.chatbotState);
          updateResponseCount(data.responseCount)
          updateSuggestion1(data.suggestion1)
          updateSuggestion2(data.suggestion2)
          updateSuggestion3(data.suggestion3)
          updateFeedbackInstructions(data.feedbackInstructions);
          setChatbot(data);

        } else {
          showToast('error', t('individualChatbot.errors.chatbotNotFound'), themeMode)
        }
      } catch (error: any) {
        showToast('error', t('individualChatbot.errors.fetchChatbotError'), themeMode)
      } finally {
        setLoading(false);
      }
    };

    fetchChatbot();
  }, []);

  useEffect(() => {
    if (chatbot) {
      const baseURL = window.location.origin;
      const iframeURL = `${baseURL}/chat/${id}`;

      const code = `<!-- Start of chatbot iframe -->
<div id="fastalk-chatbot-container" data-chatbot-url="${iframeURL}" data-button-color="${chatbot?.primaryColor}"></div>

<link rel="stylesheet" href="${baseURL}/fastalk-iframe.css">

<script src="${baseURL}/fastalk-iframe.js" defer></script>
<!-- End of chatbot iframe -->`;

      setIframeCode(code);
      setChatbotURL(iframeURL);
    }
  }, [chatbot, id]);


  const copyIframeCodeToClipboard = () => {
    navigator.clipboard.writeText(iframeCode).then(() => {
      showToast('success', t('individualChatbot.success.iframeCodeCopied'), themeMode)
    }, () => {
      showToast('error', t('individualChatbot.errors.iframeCodeCopyFail'), themeMode)
    });
  };

  const copyLinkToClipboard = () => {
    const chatbotLink = `${window.location.origin}/chat/${id}`;
    navigator.clipboard.writeText(chatbotLink).then(() => {
      showToast('success', t('individualChatbot.success.linkCopied'), themeMode)
    }, () => {
      showToast('error', t('individualChatbot.errors.linkCopyFail'), themeMode)
    });
  };

  const AISupportToggle = () => {
    setCopyIframeModalOpen(false)
    toggleChatbot()
  }

  const navigate = useNavigate();

  const handleEdit = (botId: any) => {
    navigate(`/edit-bot/${botId}`);
  };

  const { themeMode } = useContext(ConfigContext)

  const [isCopyIframeModalOpen, setCopyIframeModalOpen] = useState(false);
  const [isShareChatbotModalOpen, setShareChatbotModalOpen] = useState(false);

  // Funciones para manejar la apertura de los modales
  const handleOpenCopyIframeModal = () => setCopyIframeModalOpen(true);
  const handleOpenShareChatbotModal = () => setShareChatbotModalOpen(true);
  // const handleOpenAutoEmailModal = () => setAutoEmailModalOpen(true);

  // Funciones para manejar el cierre de los modales
  const handleCloseCopyIframeModal = () => setCopyIframeModalOpen(false);
  const handleCloseShareChatbotModal = () => setShareChatbotModalOpen(false);

  return (
    <SectionContainer
      title={chatbot?.companyName}
      individualChatbot
      text={t('individualChatbot.edit') || ""}
      icon={<ModeEditRoundedIcon />}
      onClick={() => handleEdit(id)}
    >
      {loading ? (
        <Box sx={{ height: '80vh', backgroundColor: themeMode === "dark" ? '#121212' : '#fafafa', }}>
          <Box sx={{ display: 'flex', mx: 'auto', alignItems: "center", justifyContent: 'center', mt: '100px' }}>
            <CircularProgress sx={{ color: themeMode === "dark" ? '#fafafa' : '#4a3aff' }} />
          </Box>
        </Box>
      ) : (
        <Stack flexDirection={'row'} flexWrap={isMobile ? "wrap-reverse" : "nowrap"} justifyContent="space-between" alignItems="flex-start" gap={4}
          sx={{ maxWidth: '1000px', mx: 'auto', mb: isMobile ? '60px' : 0 }}
        >
          <Stack flexDirection="column" width="100%">
            {/* <img src={themeMode === "dark" ? chatbot?.logo : chatbot?.lightModeLogo} alt="Logo del chatbot" style={{ marginTop: '15px', maxHeight: '100%', maxWidth: '200px' }} /> */}
            <Stack flexDirection="column" sx={{ maxWidth: '100%' }}>

              <Stack sx={{
                mb: '20px', backgroundColor: themeMode === "dark" ? "#181818" : "#fff",
                boxShadow: themeMode === "dark"
                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                borderRadius: '30px', p: '20px'
              }}>
                <Typography sx={{ color: themeMode === 'dark' ? "#fafafa" : '#181818', fontSize: '22px', fontWeight: '500' }}>
                  {t('individualChatbot.titles.shareChatbotWithYourClients')}
                </Typography>
                <Typography sx={{ color: themeMode === 'dark' ? "#fafafa" : '#181818', mb: '10px', fontSize: '14px', fontWeight: '300' }}>
                  {t('individualChatbot.descriptions.shareChatbotDescription')}
                </Typography>

                <Button startIcon={<LinkRoundedIcon />} onClick={handleOpenShareChatbotModal}
                  sx={{
                    height: '50px',
                    lineHeight: 1,
                    maxWidth: isMobile ? '100%' : '250px',
                    color: '#fff',
                    fontSize: isMobile ? '16px' : "16px",
                    backgroundColor: '#4a3aff',
                    fontWeight: "500",
                    textTransform: "none",
                    padding: "10px 20px",
                    borderRadius: '100px',
                    borderColor: "transparent",
                    borderWidth: 1,
                    borderStyle: 'solid',
                    fontFamily: 'Outfit',
                    mt: '10px',
                    '&:hover': {
                      borderColor: '#4a3aff',
                      color: themeMode === 'dark' ? "white" : '#181818',
                      backgroundColor: 'transparent',
                      borderWidth: 1,
                      borderStyle: 'solid',
                    },
                    '&:focus': {
                      outline: 'none',
                    },
                  }}
                >
                  {t('individualChatbot.actions.shareChatbot')}
                </Button>
              </Stack>

              <Stack sx={{
                mb: '20px', backgroundColor: themeMode === "dark" ? "#181818" : "#fff",
                boxShadow: themeMode === "dark"
                  ? '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)'
                  : '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
                borderRadius: '30px', p: '20px'
              }}>
                <Typography sx={{ color: themeMode === 'dark' ? "#fafafa" : '#181818', fontSize: '22px', fontWeight: '500' }}>
                  {t('individualChatbot.titles.integrateChatbotInYourWebsite')}
                </Typography>
                <Typography sx={{ color: themeMode === 'dark' ? "#fafafa" : '#181818', mb: '10px', fontSize: '14px', fontWeight: '300' }}>
                  {t('individualChatbot.descriptions.integrateChatbotDescription')}
                </Typography>
                <Button startIcon={<CodeRoundedIcon />} onClick={handleOpenCopyIframeModal}
                  sx={{
                    height: '50px',
                    lineHeight: 1,
                    maxWidth: isMobile ? '100%' : '250px',
                    color: '#fff',
                    fontSize: isMobile ? '16px' : "16px",
                    backgroundColor: '#4a3aff',
                    fontWeight: "500",
                    textTransform: "none",
                    padding: "10px 20px",
                    borderRadius: '100px',
                    borderColor: "transparent",
                    borderWidth: 1,
                    borderStyle: 'solid',
                    fontFamily: 'Outfit',
                    mb: '10px',
                    mt: '10px',
                    '&:hover': {
                      borderColor: '#4a3aff',
                      color: themeMode === 'dark' ? "white" : '#181818',
                      backgroundColor: 'transparent',
                      borderWidth: 1,
                      borderStyle: 'solid',
                    },
                    '&:focus': {
                      outline: 'none',
                    },
                  }}
                >
                  {t('individualChatbot.actions.startIntegration')}
                </Button>
              </Stack>
            </Stack>
          </Stack>
          {
            chatbotURL && (
              <Box sx={{
                borderRadius: '30px',
                width: '100%',
                maxWidth: '400px',
                height: '652px',
                border: themeMode === "dark" ? '1px solid #282828' : '1px solid #c1c1c1',
                mx: 'auto'
              }}>
                <iframe
                  src={chatbotURL}
                  style={{
                    width: '100%',
                    height: '650px',
                    maxWidth: '400px',
                    border: 'none',
                    overflow: 'hidden',
                    borderRadius: '30px',
                  }}
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title="Chatbot"
                />
              </Box>
            )
          }
        </Stack>
      )
      }

      <DialogCommon
        kTitle={t('individualChatbot.integrateChatbotDialog.title')}
        kMaxWidth="md"
        kMaxHeight={isMobile ? '100vh' : '650px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius={isMobile ? '20px' : '30px'}
        kAlignBtn="center"
        kTopDivider
        kSlideTransition
        kOpenDialogLists={isCopyIframeModalOpen}
        kOnClose={handleCloseCopyIframeModal}
        kOnPrimary={handleCloseCopyIframeModal}
        kPrimaryBtnText={t('individualChatbot.integrateChatbotDialog.ready') || ""}
        kAlignItemsStart
        kContent={
          <Stack flexDirection={isMobile ? "column" : "row"} justifyContent="center" alignItems="start"
            gap={4} sx={{ p: isMobile ? 0 : '20px', width: isMobile ? '90vw' : '100%', py: isMobile ? '30px' : 0, overflowX: 'hidden', }}>
            <Stack flexDirection="column">

              <Typography sx={{
                fontWeight: '300', fontFamily: 'Outfit',
                fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "#fafafa" : "#181818", mb: 2
              }}>
                {t('individualChatbot.integrateChatbotDialog.steps.start')}
              </Typography>

              <Typography sx={{
                fontWeight: '300', fontFamily: 'Outfit',
                fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "#fafafa" : "#181818", mb: 2
              }}>
                {t('individualChatbot.integrateChatbotDialog.steps.copyIframeCode')}
              </Typography>

              <Typography sx={{
                fontWeight: '300', fontFamily: 'Outfit',
                fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "#fafafa" : "#181818", mb: 2
              }}>
                {t('individualChatbot.integrateChatbotDialog.steps.insertCodeInWebsite')}
              </Typography>

              <Typography sx={{
                fontWeight: '300', fontFamily: 'Outfit',
                fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "#fafafa" : "#181818", mb: 2
              }}>
                {t('individualChatbot.integrateChatbotDialog.steps.customizeAsNeeded')}
              </Typography>

              <Typography sx={{
                fontWeight: '300', fontFamily: 'Outfit',
                fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "#fafafa" : "#181818", mb: 2,
                mt: '20px'
              }}>
                {t('individualChatbot.integrateChatbotDialog.steps.needHelp')}
              </Typography>
              <Stack flexDirection="row" justifyContent="start" alignItems="center" gap={1}>
                {/* <Button startIcon={<ArticleRoundedIcon />} onClick={copyIframeCodeToClipboard}
                  sx={{
                    height: '50px',
                    lineHeight: 1,
                    maxWidth: '200px',
                    color: themeMode === "dark" ? "#fafafa" : "#181818",
                    fontSize: isMobile ? '16px' : "16px",
                    backgroundColor: 'transparent',
                    fontWeight: "500",
                    textTransform: "none",
                    padding: "10px 20px",
                    borderRadius: '100px',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    fontFamily: 'Outfit',
                    borderColor: '#4a3aff',
                    '&:hover': {
                      borderColor: '#4a3aff',
                      color: themeMode === 'dark' ? "white" : '#181818',
                      backgroundColor: 'transparent',
                      borderWidth: 1,
                      borderStyle: 'solid',
                    },
                    '&:focus': {
                      outline: 'none',
                    },
                  }}
                >
                  {t('individualChatbot.integrateChatbotDialog.actions.goToTutorial')}
                </Button> */}
                <Button startIcon={<SupportAgentRoundedIcon />} onClick={AISupportToggle}
                  sx={{
                    height: '50px',
                    lineHeight: 1,
                    maxWidth: '200px',
                    color: themeMode === "dark" ? "#fafafa" : "#181818",
                    fontSize: isMobile ? '16px' : "16px",
                    backgroundColor: 'transparent',
                    fontWeight: "500",
                    textTransform: "none",
                    padding: "10px 20px",
                    borderRadius: '100px',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    fontFamily: 'Outfit',
                    borderColor: '#4a3aff',
                    '&:hover': {
                      borderColor: '#4a3aff',
                      color: themeMode === 'dark' ? "white" : '#181818',
                      backgroundColor: 'transparent',
                      borderWidth: 1,
                      borderStyle: 'solid',
                    },
                    '&:focus': {
                      outline: 'none',
                    },
                  }}
                >
                  {t('individualChatbot.integrateChatbotDialog.actions.iaSupport')}
                </Button>
              </Stack>

            </Stack>
            <Stack flexDirection="column" sx={{ width: '100%' }}>
              <Typography sx={{
                fontWeight: '300', fontFamily: 'Outfit',
                fontSize: isMobile ? '14px' : '16px', color: themeMode === "dark" ? "#fafafa" : "#181818", mb: 2
              }}>
                {t('individualChatbot.integrateChatbotDialog.iframeCodeLabel')}
              </Typography>
              <Box
                sx={{
                  backgroundColor: themeMode === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.05)",
                  borderRadius: '12px',
                  p: 2,
                  fontFamily: 'monospace',
                  fontSize: '0.875rem',
                  overflow: 'auto',
                  whiteSpace: "pre-wrap",
                  color: themeMode === "dark" ? "#fafafa" : "#181818",
                  maxWidth: '100%',
                  mx: 'auto'
                }}
              >
                {
                  iframeCode && (
                    iframeCode
                  )
                }

              </Box>
              <Button startIcon={<ContentCopyRoundedIcon />} onClick={copyIframeCodeToClipboard}
                sx={{
                  height: '50px',
                  lineHeight: 1,
                  maxWidth: '200px',
                  color: themeMode === "dark" ? "#fafafa" : "#181818",
                  fontSize: isMobile ? '16px' : "16px",
                  backgroundColor: 'transparent',
                  fontWeight: "500",
                  textTransform: "none",
                  padding: "10px 20px",
                  borderRadius: '100px',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  fontFamily: 'Outfit',
                  borderColor: '#4a3aff',
                  mt: '20px',
                  '&:hover': {
                    borderColor: '#4a3aff',
                    color: themeMode === 'dark' ? "white" : '#181818',
                    backgroundColor: 'transparent',
                    borderWidth: 1,
                    borderStyle: 'solid',
                  },
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              >
                {t('individualChatbot.integrateChatbotDialog.actions.copyCode')}
              </Button>
            </Stack>

          </Stack>
        }
      />

      {/* Modal para "Compartir chatbot" */}
      <DialogCommon
        kTitle={t('shareChatbotDialog.title')}
        kMaxWidth="sm"
        kMaxHeight={isMobile ? '350px' : '300px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kEnableBorderRadius={true}
        kBorderRadius={isMobile ? '20px' : '30px'}
        kAlignBtn="center"
        kTopDivider
        kSlideTransition
        kOpenDialogLists={isShareChatbotModalOpen}
        kOnClose={handleCloseShareChatbotModal}
        kOnPrimary={handleCloseShareChatbotModal}
        kPrimaryBtnText={t('shareChatbotDialog.actions.ready') || ""}
        kContent={
          <Stack>
            <Typography sx={{
              fontWeight: '400', fontFamily: 'Outfit',
              fontSize: isMobile ? '16px' : '16px', color: themeMode === "dark" ? "#fafafa" : "#181818", mb: '20px'
            }}>
              {t('shareChatbotDialog.description')}
            </Typography>

            <Stack flexDirection="column" alignItems="center" justifyContent="center" gap={3}>
              <Button
                sx={{
                  height: '50px',
                  lineHeight: 1,
                  maxWidth: '200px',
                  color: themeMode === "dark" ? "#fafafa" : "#181818",
                  fontSize: isMobile ? '16px' : "16px",
                  backgroundColor: 'transparent',
                  fontWeight: "500",
                  textTransform: "none",
                  padding: "10px 20px",
                  borderRadius: '100px',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  fontFamily: 'Outfit',
                  borderColor: '#4a3aff',
                  mt: '20px',
                  '&:hover': {
                    borderColor: '#4a3aff',
                    color: themeMode === 'dark' ? "white" : '#181818',
                    backgroundColor: 'transparent',
                    borderWidth: 1,
                    borderStyle: 'solid',
                  },
                  '&:focus': {
                    outline: 'none',
                  },
                }}
                startIcon={<ContentCopyRoundedIcon />}
                onClick={copyLinkToClipboard}
              >
                {t('shareChatbotDialog.actions.copyLink')}
              </Button>
            </Stack>

          </Stack>
        }
      />

    </SectionContainer >
  );
}

export default IndividualChatbot;
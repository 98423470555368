// services/aiService.ts
import axios from 'axios';
import { Publication } from '../../interfaces/Publication';

interface GeneratePublicationsParams {
  numberOfPublications: number;
  templateId: string;
  imageIds: string[];
  personalizationData: any; // Puedes usar un tipo más específico si tienes uno definido
}

export const generatePublications = async ({
  numberOfPublications,
  imageIds,
  personalizationData,
}: GeneratePublicationsParams): Promise<Publication[]> => {
  const systemMessage = {
    role: 'system',
    content: 'Eres un asistente que genera publicaciones de marketing personalizadas.',
  };

  const userMessage = {
    role: 'user',
    content: `
Genera ${numberOfPublications} publicaciones distintas para una campaña de marketing. Cada publicación debe incluir los siguientes campos en formato JSON:

- "title": Título de la publicación.
- "insideDescription": Texto dentro de la imagen.
- "cta": Llamado a la acción.
- "outsideDescription": Descripción que acompaña a la publicación en Instagram.
- "templateType": Tipo de plantilla a utilizar (ejemplo: "oscuro", "claro", "moderno").
- "imageId": ID de la imagen seleccionada de la siguiente lista.

Imágenes disponibles:
${JSON.stringify(
      imageIds.map((id, index) => ({
        id,
        description: `Imagen seleccionada ${index + 1}`,
      }))
    )}

Personalización de contenido:
${JSON.stringify(personalizationData)}

Selecciona las plantillas, estilos de letra, imágenes y textos de manera que sean coherentes y atractivos. Asegúrate de no repetir estilos y que cada publicación tenga un enfoque único.

Devuelve únicamente el JSON con las publicaciones.
    `,
  };

  const apiRequestBody = {
    model: 'gpt-4',
    messages: [systemMessage, userMessage],
  };

  try {
    const response = await axios.post('https://api.openai.com/v1/chat/completions', apiRequestBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      },
    });

    const messageContent = response.data.choices[0].message.content;

    // Parseamos la respuesta JSON
    const publications: Publication[] = JSON.parse(messageContent);

    return publications;
  } catch (error) {
    console.error('Error generating publications:', error);
    throw error;
  }
};

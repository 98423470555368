// WaitingPage.tsx

import { Stack } from "@mui/material";

export default function WaitingPage() {
  return (
    <Stack justifyContent="center" alignItems="center" sx={{ height: '100vh', backgroundColor: '#121212', paddingBottom: '100px' }}>
      <div className="animated-background">
        <h1>👋 ¡Bienvenido! Acércate para hablar conmigo</h1>
        <audio autoPlay loop src="/sounds/welcome.mp3" />
      </div>
    </Stack>
  );
}
import { useState, useContext, useEffect } from 'react';
import { Box, Stack, Typography, Grid, IconButton, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { ConfigContext } from '../../context/Config/ConfigContext';
import StoreContext from '../../context/Store/storeContext';
import SectionContainer from '../../global/SectionContainer';
import { useTranslation } from 'react-i18next';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogCommon from '../../components/DialogCommon/DialogCommon';
import { showToast } from '../../components/Toast/toast';
import { auth, db } from '../../services/firebase/firebase';
import { collection, query, where, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function PublicationsList() {
  const { t } = useTranslation('global');
  const { themeMode } = useContext(ConfigContext);
  const { user } = useContext(StoreContext);
  const [publications, setPublications] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedPublication, setSelectedPublication] = useState<any>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedPublicationToEdit, setSelectedPublicationToEdit] = useState<any>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedDescription, setEditedDescription] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPublications = async () => {
      if (user) {
        try {
          const q = query(collection(db, 'publications'), where('ownerId', '==', auth.currentUser?.uid));
          const querySnapshot = await getDocs(q);
          const publicationsData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          setPublications(publicationsData);
        } catch (error) {
          console.error('Error fetching publications:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPublications();
  }, [user]);

  const handleOpenDeleteDialog = (publication: any) => {
    setSelectedPublication(publication);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSelectedPublication(null);
  };

  const handleDeletePublication = async () => {
    if (selectedPublication) {
      setIsDeleting(true);
      try {
        await deleteDoc(doc(db, 'publications', selectedPublication.id));
        setPublications(prev => prev.filter(pub => pub.id !== selectedPublication.id));
        showToast('success', t('publicationsPage.publicationDeleted'), themeMode);
      } catch (error) {
        console.error('Error deleting publication:', error);
        showToast('error', t('publicationsPage.errorDeletingPublication'), themeMode);
      } finally {
        setIsDeleting(false);
        handleCloseDeleteDialog();
      }
    }
  };

  const handleOpenEditDialog = (publication: any) => {
    setSelectedPublicationToEdit(publication);
    setEditedDescription(publication.description);
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setSelectedPublicationToEdit(null);
    setEditedDescription('');
  };

  const handleEditPublication = async () => {
    if (selectedPublicationToEdit) {
      setIsEditing(true);
      try {
        await updateDoc(doc(db, 'publications', selectedPublicationToEdit.id), {
          description: editedDescription,
        });
        setPublications(prev =>
          prev.map(pub =>
            pub.id === selectedPublicationToEdit.id ? { ...pub, description: editedDescription } : pub
          )
        );
        showToast('success', t('publicationsPage.publicationUpdated'), themeMode);
      } catch (error) {
        console.error('Error updating publication:', error);
        showToast('error', t('publicationsPage.errorUpdatingPublication'), themeMode);
      } finally {
        setIsEditing(false);
        handleCloseEditDialog();
      }
    }
  };

  const handleAddPublication = () => {
    navigate('/create-publication');
  };

  return (
    <SectionContainer
      title={t('publicationsPage.title') as string}
      text={t('publicationsPage.addPublication') as string}
      icon={<AddRoundedIcon />}
      onClick={handleAddPublication}
      disableButton={false}
    >
      {loading ? (
        <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2} sx={{ mt: '100px' }}>
          <CircularProgress sx={{ color: themeMode === 'dark' ? '#fafafa' : '#4a3aff' }} />
        </Stack>
      ) : publications.length === 0 ? (
        <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={2} sx={{ mt: '100px', padding: '20px' }}>
          <Typography align="center" sx={{
            fontSize: isMobile ? '16px' : '18px', fontFamily: 'Outfit', fontWeight: '300',
            color: themeMode === 'dark' ? '#c1c1c1' : '#c1c1c1', maxWidth: '600px'
          }}>
            {t('publicationsPage.noPublications')}
          </Typography>
        </Stack>
      ) : (
        <Box sx={{ mt: '20px', mb: isMobile ? '70px' : '30px' }}>
          <Grid container spacing={2}>
            {publications.map((publication: any, index: number) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box
                  sx={{
                    backgroundColor: themeMode === 'dark' ? '#181818' : '#fff',
                    borderRadius: '15px',
                    padding: '16px',
                    boxShadow: themeMode === 'dark'
                      ? '0 4px 6px rgba(0, 0, 0, 0.05)'
                      : '0 4px 6px rgba(0, 0, 0, 0.05)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ position: 'relative' }}>
                    <img
                      src={publication.imageUrl}
                      alt={publication.title}
                      style={{ width: '100%', borderRadius: '15px' }}
                    />
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: 10,
                        right: 55,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: '#fff',
                        borderRadius: '50%',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        },
                        '&:focus': {
                          outline: 'none',
                        },
                      }}
                      onClick={() => handleOpenEditDialog(publication)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: '#fff',
                        borderRadius: '50%',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        },
                        '&:focus': {
                          outline: 'none',
                        },
                      }}
                      onClick={() => handleOpenDeleteDialog(publication)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                  <Typography variant="h6" sx={{ color: themeMode === 'dark' ? '#fafafa' : '#181818', mt: 2 }}>
                    {publication.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: themeMode === 'dark' ? '#c1c1c1' : '#4a4a4a', mt: 1 }}>
                    {publication.description}
                  </Typography>
                  <Typography variant="caption" sx={{ color: themeMode === 'dark' ? '#c1c1c1' : '#4a4a4a', mt: 1 }}>
                    {t('publicationsPage.publishedOn')} {new Date(publication.timestamp?.toDate()).toLocaleDateString()}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* Dialogo para eliminar publicación */}
      <DialogCommon
        kTitle={t('publicationsPage.deleteConfirm') as string}
        kOpenDialogLists={deleteDialogOpen}
        kOnClose={handleCloseDeleteDialog}
        kOnPrimary={handleDeletePublication}
        kOnSecondary={handleCloseDeleteDialog}
        kPrimaryBtnText={t('publicationsPage.delete') as string}
        kSecondaryBtnText={t('publicationsPage.cancel') as string}
        kDisableActions={isDeleting}
        kContent={
          isDeleting ? (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress sx={{ color: themeMode === 'dark' ? '#fafafa' : '#4a3aff' }} />
            </Box>
          ) : (
            <Typography sx={{ color: themeMode === 'dark' ? '#fafafa' : '#181818' }}>
              {t('publicationsPage.deleteMessage')}
            </Typography>
          )
        }
      />

      {/* Dialogo para editar publicación */}
      <DialogCommon
        kTitle={t('publicationsPage.editPublication') as string}
        kOpenDialogLists={editDialogOpen}
        kOnClose={handleCloseEditDialog}
        kOnPrimary={handleEditPublication}
        kOnSecondary={handleCloseEditDialog}
        kPrimaryBtnText={t('publicationsPage.save') as string}
        kSecondaryBtnText={t('publicationsPage.cancel') as string}
        kDisableActions={isEditing}
        kContent={
          isEditing ? (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress sx={{ color: themeMode === 'dark' ? '#fafafa' : '#4a3aff' }} />
            </Box>
          ) : (
            <Box>
              <Typography sx={{ color: themeMode === 'dark' ? '#fafafa' : '#181818', mb: 2 }}>
                {t('publicationsPage.editDescription')}
              </Typography>
              <textarea
                value={editedDescription}
                onChange={(e) => setEditedDescription(e.target.value)}
                style={{
                  width: '100%',
                  height: '100px',
                  padding: '10px',
                  borderRadius: '10px',
                  border: '1px solid #ccc',
                  fontFamily: 'Outfit',
                }}
              />
            </Box>
          )
        }
      />
    </SectionContainer>
  );
}

export default PublicationsList;

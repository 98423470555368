import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Box,
  Button, Input,
  Stack,
  TextField,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme, Grid, CircularProgress
} from "@mui/material";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import AddAPhotoRoundedIcon from "@mui/icons-material/AddAPhotoRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { AnimatePresence, motion } from "framer-motion";
import { ConfigContext } from "../../context/Config/ConfigContext";
import { showToast } from "../../components/Toast/toast";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { extractColors } from 'extract-colors';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import './VoodoPage.css';
import VoodoBot from "./VoodoBot";
import whiteLogo from "../../assets/whiteLogo.png";
import blackLogo from "../../assets/mainLogo.png";
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import AutoModeRoundedIcon from '@mui/icons-material/AutoModeRounded';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import BlurOnRoundedIcon from '@mui/icons-material/BlurOnRounded';
import QuizRoundedIcon from '@mui/icons-material/QuizRounded';
import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import { useNavigate, useSearchParams } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";

const VoodooPage: React.FC = () => {
  const { themeMode, toggleTheme } = useContext(ConfigContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const {
    logo,
    lightModeLogo,
    primaryColor,
    companyName,
    assistantFirstMessage,
    systemInstructions,
    chatbotTone,
    chatbotFormality,
    defaultLanguage,
    useEmojis,
    chatbotPersonality,
    nightMode,
    businessType,
    suggestion1,
    suggestion2,
    suggestion3,
    updateSystemInstructions,
    updateAssistantFirstMessage,
    updatePrimaryColor,
    updateCompanyLogo,
    updateCompanyLightModeLogo,
    updateCompanyName,
    updateBusinessType,
    updateSuggestion1,
    updateSuggestion2,
    updateSuggestion3,
    resetConfigContext
  } = useContext(ConfigContext);


  const [step, setStep] = useState(1);
  const [urls, setUrls] = useState([""]);
  const [hasWebsite, setHasWebsite] = useState(true);
  const [websiteDescription, setWebsiteDescription] = useState("");
  const [websiteDescriptionError, setWebsiteDescriptionError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chatbotCreated, setChatbotCreated] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dynamicColor, setDynamicColor] = useState("#4a3aff");
  const [showDynamicColor, setShowDynamicColor] = useState(false);

  const [progress, setProgress] = useState(0);
  const [messageIndex, setMessageIndex] = useState(0);
  const [showCompletionMessage, setShowCompletionMessage] = useState(false);

  const themeModeRef = useRef(themeMode);

  useEffect(() => {
    themeModeRef.current = themeMode;
  }, [themeMode]);

  const [logoVersion, setLogoVersion] = useState(0);

  const loadingMessages = [
    { text: "Recopilando datos de tu negocio...", icon: <ArchiveRoundedIcon sx={{ fontSize: isMobile ? 50 : 60, color: "#4a3aff" }} /> },
    { text: "Procesando y entendiendo la información...", icon: <AutoModeRoundedIcon sx={{ fontSize: isMobile ? 50 : 60, color: "#4a3aff" }} /> },
    { text: "Resumiendo los detalles clave...", icon: <LibraryBooksRoundedIcon sx={{ fontSize: isMobile ? 50 : 60, color: "#4a3aff" }} /> },
    { text: "Entrenando la inteligencia artificial con tus datos...", icon: <BlurOnRoundedIcon sx={{ fontSize: isMobile ? 50 : 60, color: "#4a3aff" }} /> },
    { text: "Generando preguntas frecuentes sugeridas...", icon: <QuizRoundedIcon sx={{ fontSize: isMobile ? 50 : 60, color: "#4a3aff" }} /> },
    { text: "Personalizando la interacción del chatbot...", icon: <ColorLensRoundedIcon sx={{ fontSize: isMobile ? 50 : 60, color: "#4a3aff" }} /> },
    { text: "Aplicando los últimos ajustes para tu chatbot...", icon: <AutoAwesomeRoundedIcon sx={{ fontSize: isMobile ? 50 : 60, color: "#4a3aff" }} /> }
  ];

  const themee = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: "Outfit",
            border: themeMode === "dark" ? "1px solid #272727" : "1px solid #eeeeee",
            backgroundColor: themeMode === "dark" ? "#181818" : "#fafafa",
            borderRadius: 30,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : "transparent",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#181818" : "#fafafa",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : "transparent",
            },
            "& .MuiInputBase-input": {
              fontFamily: "Outfit",
              color: themeMode === "dark" ? "#fafafa" : "#121212",
            },
            "& .MuiInputLabel-root": {
              fontFamily: "Outfit",
              color: themeMode === "dark" ? "#fafafa" : "#121212",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: themeMode === "dark" ? "#fafafa" : "#121212",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : "#4a3aff",
            },
            "& .MuiInputLabel-root.Mui-focused + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : "#4a3aff",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 30,
          },
        },
      },
    },
  });

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--color-swatch-background',
      themeMode === "dark" ? "#181818" : "#ffffff"
    );
  }, [themeMode]);

  const [isConfigLoading, setIsConfigLoading] = useState(true);

  useEffect(() => {
    const config = localStorage.getItem('chatbotConfig');
    if (config) {
      setStep(4);
      setChatbotCreated(true);
    }
    setIsConfigLoading(false);
  }, []);


  const [extractedColors, setExtractedColors] = useState<string[]>([]);

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          const currentThemeMode = themeModeRef.current;
          if (currentThemeMode === 'dark') {
            updateCompanyLogo(reader.result);
          } else {
            updateCompanyLightModeLogo(reader.result);
          }
          extractColorsFromImage(reader.result);
          setLogoVersion((prevVersion) => prevVersion + 1);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const extractColorsFromImage = async (imageSrc: string) => {
    try {
      const colors = await extractColors(imageSrc);
      if (colors.length > 0) {
        updatePrimaryColor(colors[0].hex);
        setExtractedColors(colors.map((color: any) => color.hex));
      }
    } catch (error) {
      console.error("Error al extraer colores del logo:", error);
    }
  };

  const handleColorSelect = (color: string) => {
    updatePrimaryColor(color);
  };

  const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCompanyName(e.target.value);
  };

  const formatUrl = (url: string) => {
    let formattedUrl = url.trim();

    if (!formattedUrl.startsWith("https://") && !formattedUrl.startsWith("http://")) {
      if (formattedUrl.startsWith("www.")) {
        formattedUrl = "https://" + formattedUrl.substring(4);
      } else {
        formattedUrl = "https://" + formattedUrl;
      }
    }

    return formattedUrl;
  };

  const handleUrlChange = (index: number, value: string) => {
    const newUrls = [...urls];
    newUrls[index] = value;
    setUrls(newUrls);
  };

  const handleAddUrlField = () => {
    if (urls.length < 5) {
      setUrls([...urls, ""]);
    }
  };

  const handleRemoveUrlField = () => {
    if (urls.length > 1) {
      const newUrls = urls.slice(0, -1);
      setUrls(newUrls);
    }
  };

  const handleExtractText = async () => {
    const formattedUrls = urls.map((url) => formatUrl(url));

    const newUrlErrors = formattedUrls.map((url) => url.trim() === "");
    if (newUrlErrors.includes(true)) {
      return null;
    }

    try {
      const response = await fetch(
        `${process.env.VITE_APP_BACKEND_API}/extractTextFromPage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            urls: formattedUrls,
            analyzeBenefits: true,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();

      if (data.benefits) {
        localStorage.removeItem("benefitsData");
        localStorage.setItem("benefitsData", JSON.stringify(data.benefits));
      }

      return data;
    } catch (error) {
      showToast("error", "Error al extraer datos", themeMode);
      return null;
    }
  };

  const handleProcessDescription = async (description: string) => {
    try {
      const finalDescription = `${companyName ? companyName + ' - ' : ''}${description}`;

      const response = await fetch(
        `${process.env.VITE_APP_BACKEND_API}/processBusinessDescription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            description: finalDescription,
            analyzeBenefits: true,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();

      if (data.benefits) {
        localStorage.removeItem("benefitsData");
        localStorage.setItem("benefitsData", JSON.stringify(data.benefits));
      }

      return data;
    } catch (error) {
      showToast("error", "Error al procesar la descripción", themeMode);
      return null;
    }
  };

  const handleInitialConfig = async () => {
    setIsLoading(true);
    setProgress(0);
    setMessageIndex(0);
    setShowCompletionMessage(false);

    const totalMessages = loadingMessages.length;
    const progressIncrement = 100 / totalMessages;
    const intervalDuration = 2000;

    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        const newProgress = prev + progressIncrement;
        if (newProgress >= 100) {
          clearInterval(progressInterval);
          return 100;
        }
        return newProgress;
      });
    }, intervalDuration);

    const messageInterval = setInterval(() => {
      setMessageIndex((prev) => {
        if (prev >= totalMessages - 1) {
          clearInterval(messageInterval);
          return prev;
        }
        return prev + 1;
      });
    }, intervalDuration);

    let extractedDataPromise;
    if (hasWebsite) {
      extractedDataPromise = handleExtractText();
    } else {
      if (!websiteDescription.trim()) {
        setWebsiteDescriptionError(true);
        setIsLoading(false);
        return;
      }
      extractedDataPromise = handleProcessDescription(websiteDescription);
    }

    const [extractedData] = await Promise.all([extractedDataPromise]);

    setShowCompletionMessage(true);
    setProgress(100);
    clearInterval(progressInterval);
    clearInterval(messageInterval);

    setTimeout(() => {
      if (extractedData) {
        updateSystemInstructions(extractedData.companyDescription);
        updateSuggestion1(extractedData.suggestion1);
        updateSuggestion2(extractedData.suggestion2);
        updateSuggestion3(extractedData.suggestion3);
        updateAssistantFirstMessage(extractedData.firstAssistantMessage);
        updateBusinessType(extractedData.businessType);

        if (extractedData.benefits) {
          localStorage.removeItem("benefitsData");
          localStorage.setItem("benefitsData", JSON.stringify(extractedData.benefits));
        }

        updateGlobalMetrics("chatbotsCreated");

        setChatbotCreated(true);
        resetConfigContext();
        setStep(4);
      } else {
        showToast("error", "Error al procesar datos", themeMode);
      }

      setIsLoading(false);
    }, 1500);
  };


  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          const currentThemeMode = themeModeRef.current;
          if (currentThemeMode === 'dark') {
            updateCompanyLogo(reader.result);
          } else {
            updateCompanyLightModeLogo(reader.result);
          }
          extractColorsFromImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };


  const [direction, setDirection] = useState(1);
  const [, setPrevStep] = useState(step);

  const goToNextStep = () => {
    setPrevStep(step);
    setDirection(1);
    setStep((prevStep) => prevStep + 1);
    getExitDirection()
  };

  const goToPreviousStep = () => {
    setPrevStep(step);
    setDirection(-1);
    setStep((prevStep) => prevStep - 1);
    getExitDirection()
  };

  const getExitDirection = () => {
    if (direction === -1 && step === 2) return -50;
    if (direction === -1 && step === 1) return 50;
    if (direction === 1 && step === 1) return -50;
    if (direction === -1 && step === 3) return 50;
    if (direction === 1 && step === 2) return -50;
    return direction === 1 ? -50 : 50;
  };

  const isValidURL = (url: string): boolean => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{1,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$', 'i');
    return !!urlPattern.test(url);
  };

  const navigate = useNavigate()

  // Captura el parámetro code (codigoVendedor) de la URL
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const codigoVendedorParam = searchParams.get("code");
    if (codigoVendedorParam) {
      localStorage.setItem('codigoVendedor', codigoVendedorParam);
    }
  }, [searchParams]);


  const handleSignUpAndSaveBot = async () => {
    const chatbotConfig = {
      logo,
      lightModeLogo,
      primaryColor,
      assistantFirstMessage,
      systemInstructions,
      companyName,
      chatbotTone,
      chatbotFormality,
      defaultLanguage,
      useEmojis,
      chatbotPersonality,
      nightMode,
      businessType,
      suggestion1,
      suggestion2,
      suggestion3,
    };

    localStorage.setItem('chatbotConfig', JSON.stringify(chatbotConfig));

    navigate('/signup')
  };

  const updateGlobalMetrics = async (metric: string) => {
    try {
      const functions = getFunctions();
      const incrementMetric = httpsCallable(functions, 'incrementMetric');
      await incrementMetric({ metric });
    } catch (error) {
      console.error('Error al actualizar la métrica:', error);
    }
  };

  const pageViewRef = useRef(false);

  useEffect(() => {
    if (!pageViewRef.current) {
      updateGlobalMetrics("pageViews");
      pageViewRef.current = true;
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem('benefitsData');
  }, []);


  return (
    <ThemeProvider theme={themee}>
      {isConfigLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          {
            (!isMobile || (isMobile && step !== 4)) && (
              <Box
                sx={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 1000,
                  backdropFilter: "blur(10px)",
                  bgcolor: themeMode === "dark" ? "rgba(24, 24, 24, 0.1)" : "rgba(255, 255, 255, 0.1)",
                  padding: "10px 20px",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ maxWidth: "2200px", margin: "0 auto" }}
                >
                  <Box
                    component="img"
                    src={themeMode === "dark" ? whiteLogo : blackLogo}
                    alt="Fastalk Logo"
                    sx={{
                      width: isMobile ? "120px" : "150px",
                    }}
                  />

                  <Stack flexDirection="row" justifyContent="center" alignItems="center">
                    <IconButton onClick={() => toggleTheme()} sx={{
                      "&:focus": {
                        outline: "none"
                      },
                      mr: '10px'
                    }}>
                      {themeMode !== "dark" ? <ModeNightIcon sx={{ color: '#c1c1c1' }} /> : <LightModeRoundedIcon sx={{ color: '#c1c1c1' }} />}
                    </IconButton>
                    {
                      (step === 4 && !isMobile) && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={async () => {
                            await handleSignUpAndSaveBot();
                          }}
                          sx={{
                            height: '55px',
                            backgroundColor: '#4a3aff',
                            lineHeight: 1,
                            color: '#fff',
                            fontSize: isMobile ? '16px' : "16px",
                            fontWeight: "500",
                            textTransform: "none",
                            padding: "10px 20px",
                            borderRadius: '100px',
                            borderColor: "transparent",
                            borderWidth: 1,
                            borderStyle: 'solid',
                            fontFamily: 'Outfit',
                            '&:hover': {
                              borderColor: '#4a3aff',
                              color: themeMode === 'dark' ? "white" : '#181818',
                              backgroundColor: 'transparent',
                              borderWidth: 1,
                              borderStyle: 'solid',
                            },
                            '&:focus': {
                              outline: 'none',
                            },
                            width: '90%'
                          }}
                        >
                          Guardar chatbot y probar 14 días gratis
                        </Button>
                      )
                    }
                  </Stack>
                </Stack>
              </Box>
            )
          }

          <Box
            sx={{
              maxWidth: step === 4 ? "100%" : "600px",
              mx: "auto",
              height: "100vh",
              backgroundColor: themeMode === "dark" ? "#121212" : "#fff",
            }}
          >
            <AnimatePresence mode="wait">
              {step === 1 && (
                <motion.div
                  key="step-1"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  transition={{ duration: 0.25 }}
                >
                  <Stack
                    spacing={1}
                    sx={{
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "90vh",
                      padding: '16px'
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontFamily: "Outfit",
                        color: themeMode === "dark" ? "#4a3aff" : "#4a3aff",
                        fontSize: "14px",
                      }}
                    >
                      Paso 1 de 3
                    </Typography>
                    <Typography
                      variant="h4"
                      gutterBottom
                      sx={{
                        color: themeMode === "dark" ? "#fafafa" : "#181818",
                        fontFamily: "Outfit",
                        fontWeight: "900",
                        fontSize: isMobile ? '26px' : '34px'
                      }}
                    >
                      Nombre del negocio
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: themeMode === "dark" ? "#c1c1c1" : "#7c7c7c",
                        fontFamily: "Outfit",
                      }}
                    >
                      Por favor ingresa el nombre de tu negocio para personalizar tu
                      chatbot.
                    </Typography>
                    <ThemeProvider theme={themee}>
                      <TextField
                        fullWidth
                        placeholder="Nombre del negocio"
                        variant="outlined"
                        value={companyName}
                        onChange={handleCompanyNameChange}
                        inputProps={{ maxLength: 100 }}
                        margin="normal"
                        sx={{ mb: 3, borderRadius: "150px" }}
                      />
                    </ThemeProvider>

                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={goToNextStep}
                        disabled={companyName.trim() === ""}
                        sx={{
                          height: "48px",
                          backgroundColor: "#4a3aff",
                          color: "#fff",
                          "&.Mui-disabled": {
                            backgroundColor:
                              themeMode === "dark"
                                ? "rgba(255, 255, 255, 0.12)"
                                : "rgba(0, 0, 0, 0.12)",
                            color:
                              themeMode === "dark"
                                ? "rgba(255, 255, 255, 0.3)"
                                : "rgba(0, 0, 0, 0.3)",
                          },
                          fontSize: isMobile ? "16px" : "16px",
                          fontWeight: "500",
                          textTransform: "none",
                          padding: "10px 20px",
                          borderRadius: "100px",
                          borderColor: "transparent",
                          borderWidth: 1,
                          borderStyle: "solid",
                          fontFamily: "Outfit",
                          "&:hover": {
                            borderColor: "#4a3aff",
                            color: themeMode === "dark" ? "white" : "#181818",
                            backgroundColor: "transparent",
                            borderWidth: 1,
                            borderStyle: "solid",
                          },
                          "&:focus": {
                            outline: "none",
                          },
                          mt: '30px'
                        }}
                      >
                        Siguiente
                      </Button>
                    </Box>
                  </Stack>
                </motion.div>
              )}

              {step === 2 && (
                <motion.div
                  key="step-2"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  transition={{ duration: 0.25 }}
                >
                  <Stack
                    spacing={1}
                    sx={{
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "90vh",
                      padding: '16px'
                    }}
                  >
                    <Box>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontFamily: "Outfit",
                          color: themeMode === "dark" ? "#4a3aff" : "#4a3aff",
                          fontSize: "14px",
                        }}
                      >
                        Paso 2 de 3
                      </Typography>

                      <Typography
                        variant="h4"
                        sx={{
                          color: themeMode === "dark" ? "#fafafa" : "#181818",
                          fontFamily: "Outfit",
                          fontWeight: "900",
                          fontSize: isMobile ? '26px' : '34px'
                        }}
                      >
                        Logo y colores
                      </Typography>
                    </Box>

                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: themeMode === "dark" ? "#c1c1c1" : "#7c7c7c",
                        fontFamily: "Outfit",
                      }}
                    >
                      Sube el logo de tu negocio y selecciona el color principal.
                    </Typography>

                    <Box>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: themeMode === "dark" ? "#fafafa" : "#181818",
                          fontFamily: "Outfit",
                        }}
                      >
                        Logo
                      </Typography>
                      <Box
                        sx={{
                          position: "relative",
                          width: "300px",
                          height: "150px",
                          border: isDragging ? "1px dashed #4a3aff" : "1px dashed",
                          borderColor: isDragging
                            ? "#4a3aff"
                            : themeMode === "dark"
                              ? "#434343"
                              : "#cfcfcf",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "20px",
                          borderRadius: "20px",
                          backgroundColor: themeMode === "dark" ? "#181818" : "#f5f5f5",
                          marginBottom: "10px",
                          cursor: "pointer",
                          transition: "border-color 0.2s ease-in-out",
                        }}
                        onClick={() => document.getElementById("upload-logo")?.click()}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                      >
                        {(themeMode === 'dark' ? logo : lightModeLogo) ? (
                          <motion.div
                            key={`${themeMode}-${logoVersion}`}
                            initial={{ scale: 0.5 }}
                            animate={{ scale: 1 }}
                            transition={{ duration: 0.25 }}
                          >
                            <img
                              src={themeMode === 'dark' ? logo : lightModeLogo}
                              alt="Logo de la empresa"
                              style={{
                                maxHeight: "100px",
                                maxWidth: "100%",
                                objectFit: "contain",
                                borderRadius: "4px",
                              }}
                            />
                          </motion.div>
                        ) : (
                          <Stack flexDirection="column" alignItems="center" justifyContent="center">
                            <AddRoundedIcon
                              sx={{
                                fontSize: "48px",
                                color: themeMode === "dark" ? "#fafafa" : "#7c7c7c",
                              }}
                            />
                            <Typography
                              sx={{
                                fontFamily: "Outfit",
                                fontSize: "14px",
                                color: themeMode === "dark" ? "#fafafa" : "#7c7c7c",
                                mt: 1,
                              }}
                            >
                              Arrastra y suelta tu logo aquí, o haz clic para seleccionar
                            </Typography>
                          </Stack>
                        )}

                        <IconButton
                          component="span"
                          sx={{
                            color: "white",
                            backgroundColor: "#4a3aff",
                            borderRadius: "100px",
                            fontWeight: "bold",
                            padding: "15px",
                            fontFamily: "Outfit",
                            position: "absolute",
                            right: "-25px",
                            bottom: "-25px",
                            "&:hover": {
                              backgroundColor: "#4a3aff",
                            },
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            document.getElementById("upload-logo")?.click();
                          }}
                        >
                          <AddAPhotoRoundedIcon />
                        </IconButton>
                      </Box>

                      <input
                        style={{ display: "none" }}
                        id="upload-logo"
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleLogoUpload}
                      />
                    </Box>


                    {(logo || lightModeLogo) && extractedColors.length > 0 && (
                      <AnimatePresence>
                        <motion.div
                          initial={{ opacity: 0, scale: 0.9 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.9 }}
                          transition={{ duration: 0.3, ease: "easeOut" }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: themeMode === "dark" ? "#fafafa" : "#181818",
                              fontFamily: "Outfit",
                              mt: '20px'
                            }}
                          >
                            Selecciona el color principal
                          </Typography>
                          <Grid
                            direction="row"
                            gap={isMobile ? 1 : 2}
                            sx={{
                              justifyContent: "center", mt: 1, alignItems: "center", display: 'flex',
                              flexWrap: 'wrap', maxWidth: '80vw'
                            }}
                          >
                            {extractedColors.map((color, index) => (
                              <Box
                                key={index}
                                sx={{
                                  width: "40px",
                                  height: "40px",
                                  backgroundColor: color,
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                  border:
                                    primaryColor === color
                                      ? "3px solid #4a3aff"
                                      : "2px solid transparent",
                                  transition: "all 0.15s ease",
                                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                  "&:hover": {
                                    transform: "scale(1.2)",
                                    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                                  },
                                }}
                                onClick={() => handleColorSelect(color)}
                              />
                            ))}

                            <Box
                              sx={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                backgroundColor: "#fff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                "&:hover": {
                                  transform: "scale(1.1)",
                                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                                },
                                position: "relative",
                                overflow: "hidden",
                              }}
                            >
                              <AddRoundedIcon
                                sx={{
                                  color: "#4a3aff",
                                  fontSize: "20px",
                                  zIndex: 1,
                                  pointerEvents: "none",
                                }}
                              />

                              <Input
                                type="color"
                                value={dynamicColor}
                                onChange={(e: any) => {
                                  const newColor = e.target.value;
                                  setDynamicColor(newColor);
                                  setExtractedColors((prevColors) => {
                                    if (showDynamicColor) {
                                      if (prevColors[prevColors.length - 1] !== newColor) {
                                        const updatedColors = [...prevColors];
                                        updatedColors[updatedColors.length - 1] = newColor;
                                        return updatedColors;
                                      } else {
                                        return prevColors;
                                      }
                                    } else {
                                      setShowDynamicColor(true);
                                      return [...prevColors, newColor];
                                    }
                                  });

                                  updatePrimaryColor(newColor);
                                }}
                                disableUnderline
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  opacity: 0,
                                  cursor: "pointer",
                                  zIndex: 9,
                                  '& input': {
                                    cursor: 'pointer',
                                  },
                                }}
                                id="color-input"
                              />
                            </Box>
                          </Grid>
                        </motion.div>
                      </AnimatePresence>
                    )}

                    <Box
                      sx={{ display: "flex", justifyContent: "space-between", width: "100%", pt: '30px' }}
                    >
                      <Button
                        variant="outlined"
                        onClick={goToPreviousStep}
                        sx={{
                          height: "48px",
                          backgroundColor: "transparent",
                          borderColor: "#4a3aff",
                          color: themeMode === "dark" ? "white" : "#181818",
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "none",
                          padding: "10px 20px",
                          borderRadius: "100px",
                          borderWidth: 1,
                          borderStyle: "solid",
                          fontFamily: "Outfit",
                          "&:hover": {
                            borderColor: "#4a3aff",
                            color: themeMode === "dark" ? "white" : "#181818",
                            backgroundColor: "transparent",
                            borderWidth: 1,
                            borderStyle: "solid",
                          },
                          "&:focus": {
                            outline: "none",
                          },
                          ml: "10px",
                        }}
                      >
                        Atrás
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={goToNextStep}
                        sx={{
                          height: "48px",
                          backgroundColor: "#4a3aff",
                          color: "#fff",
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "none",
                          padding: "10px 20px",
                          borderRadius: "100px",
                          borderColor: "transparent",
                          borderWidth: 1,
                          borderStyle: "solid",
                          fontFamily: "Outfit",
                          "&:hover": {
                            borderColor: "#4a3aff",
                            color: themeMode === "dark" ? "white" : "#181818",
                            backgroundColor: "transparent",
                            borderWidth: 1,
                            borderStyle: "solid",
                          },
                          "&:focus": {
                            outline: "none",
                          },
                          ml: "10px",
                        }}
                      >
                        Siguiente
                      </Button>
                    </Box>
                  </Stack>
                </motion.div>
              )}

              {!isLoading && step === 3 && (
                <motion.div
                  key="step-3"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  transition={{ duration: 0.25 }}
                >
                  <Stack
                    spacing={1}
                    sx={{
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "90vh",
                      padding: '16px'
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontFamily: "Outfit",
                        color: themeMode === "dark" ? "#4a3aff" : "#4a3aff",
                        fontSize: "14px",
                      }}
                    >
                      Paso 3 de 3
                    </Typography>
                    <Typography
                      variant="h4"
                      gutterBottom
                      sx={{
                        color: themeMode === "dark" ? "#fafafa" : "#181818",
                        fontFamily: "Outfit",
                        fontWeight: "900",
                        fontSize: isMobile ? '26px' : '34px'
                      }}
                    >
                      Información del negocio
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: themeMode === "dark" ? "#c1c1c1" : "#7c7c7c",
                        fontFamily: "Outfit",
                      }}
                    >
                      ¿Tu negocio tiene una página web?
                    </Typography>

                    <Stack direction="row" spacing={2} sx={{ marginTop: 2 }}>
                      <Button
                        variant="contained"
                        onClick={() => setHasWebsite(true)}
                        sx={{
                          backgroundColor: hasWebsite ? "#4a3aff" : "transparent",
                          color: hasWebsite
                            ? "#fff"
                            : themeMode === "dark"
                              ? "#fff"
                              : "#181818",
                          fontFamily: "Outfit",
                          textTransform: "none",
                          padding: "10px 20px",
                          borderRadius: "100px",
                          "&:hover": {
                            backgroundColor: "#4a3aff",
                            color: "#fff",
                          },
                          "&:focus": {
                            backgroundColor: "#4a3aff",
                            color: "#fff",
                            outline: "none",
                          },
                        }}
                      >
                        Sí
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => setHasWebsite(false)}
                        sx={{
                          backgroundColor: !hasWebsite ? "#4a3aff" : "transparent",
                          color: !hasWebsite
                            ? "#fff"
                            : themeMode === "dark"
                              ? "#fff"
                              : "#181818",
                          fontFamily: "Outfit",
                          textTransform: "none",
                          padding: "10px 20px",
                          borderRadius: "100px",
                          "&:hover": {
                            backgroundColor: "#4a3aff",
                            color: "#fff",
                          },
                          "&:focus": {
                            backgroundColor: "#4a3aff",
                            color: "#fff",
                            outline: "none",
                          },
                        }}
                      >
                        No
                      </Button>
                    </Stack>

                    <Box sx={{ width: "100%", maxWidth: "600px", mt: 4 }}>
                      {hasWebsite ? (
                        <>
                          <AnimatePresence>
                            {urls.map((url, index) => (
                              <motion.div
                                key={index}
                                initial={{ opacity: 0, scale: 0.9, y: 20 }}
                                animate={{ opacity: 1, scale: 1, y: 0 }}
                                transition={{ duration: 0.20 }}
                              >
                                <ThemeProvider theme={themee}>
                                  <TextField
                                    fullWidth
                                    placeholder={`Introduce el enlace a tu sitio web ${index + 1}`}
                                    variant="outlined"
                                    value={url}
                                    onChange={(e) => handleUrlChange(index, e.target.value)}
                                    inputProps={{ maxLength: 200 }}
                                    margin="normal"
                                    sx={{ mb: 0, borderRadius: "150px" }}
                                  />
                                </ThemeProvider>
                              </motion.div>
                            ))}
                          </AnimatePresence>

                          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                            <motion.div
                              whileHover={{ scale: 1.1 }}
                              transition={{ type: "spring", stiffness: 300 }}
                            >
                              <IconButton
                                onClick={handleAddUrlField}
                                sx={{
                                  color: themeMode === "dark" ? "#fff" : "#181818",
                                  backgroundColor: "#4a3aff",
                                  margin: "0 10px",
                                  "&:hover": {
                                    backgroundColor: "#3e38cc",
                                  },
                                  "&:focus": {
                                    outline: "none",
                                  },
                                }}
                                disabled={urls.length >= 5}
                              >
                                <AddRoundedIcon style={{ color: "#fff" }} />
                              </IconButton>
                            </motion.div>

                            <motion.div
                              whileHover={{ scale: 1.1 }}
                              transition={{ type: "spring", stiffness: 300 }}
                            >
                              <IconButton
                                onClick={handleRemoveUrlField}
                                disabled={urls.length <= 1}
                                sx={{
                                  color: themeMode === "dark" ? "#fff" : "#181818",
                                  backgroundColor: "#ff4c4c",
                                  margin: "0 10px",
                                  "&:hover": {
                                    backgroundColor: "#d73838",
                                  },
                                  "&.Mui-disabled": {
                                    backgroundColor:
                                      themeMode === "dark"
                                        ? "rgba(255, 255, 255, 0.1)"
                                        : "rgba(0, 0, 0, 0.1)",
                                    color:
                                      themeMode === "dark"
                                        ? "rgba(255, 255, 255, 0.3)"
                                        : "rgba(0, 0, 0, 0.3)",
                                  },
                                  "&:focus": {
                                    outline: "none",
                                  },
                                }}
                              >
                                <RemoveRoundedIcon style={{ color: "#fff" }} />
                              </IconButton>
                            </motion.div>
                          </Box>
                        </>
                      ) : (
                        <motion.div
                          initial={{ opacity: 0, scale: 0.9, y: 20 }}
                          animate={{ opacity: 1, scale: 1, y: 0 }}
                          exit={{ opacity: 0, scale: 0.9, y: 20 }}
                          transition={{ duration: 0.20 }}
                        >
                          <TextField
                            fullWidth
                            multiline
                            rows={isMobile ? 8 : 6}
                            placeholder="Describe tu negocio: ¿Cuál es tu giro, productos o servicios principales?"
                            variant="outlined"
                            value={websiteDescription}
                            onChange={(e) => setWebsiteDescription(e.target.value)}
                            inputProps={{ maxLength: 2000 }}
                            margin="normal"
                            error={websiteDescriptionError}
                            helperText={
                              websiteDescriptionError && "Este campo es obligatorio"
                            }
                            sx={{ mb: 3 }}
                          />
                        </motion.div>

                      )}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        maxWidth: "600px",
                        mt: 4,
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={goToPreviousStep}
                        sx={{
                          height: "48px",
                          backgroundColor: "transparent",
                          borderColor: "#4a3aff",
                          color: themeMode === "dark" ? "white" : "#181818",
                          fontSize: isMobile ? "16px" : "16px",
                          fontWeight: "500",
                          textTransform: "none",
                          padding: "10px 20px",
                          borderRadius: "100px",
                          borderWidth: 1,
                          borderStyle: "solid",
                          fontFamily: "Outfit",
                          "&:hover": {
                            borderColor: "#4a3aff",
                            color: themeMode === "dark" ? "white" : "#181818",
                            backgroundColor: "transparent",
                          },
                          "&:focus": {
                            outline: "none",
                          },
                        }}
                      >
                        Atrás
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleInitialConfig}
                        disabled={
                          (hasWebsite && (
                            urls.every(url => url.trim() === "") ||
                            urls.some(url => !isValidURL(url))
                          )) ||
                          (!hasWebsite && (websiteDescription.trim().length < 20))
                        }
                        sx={{
                          height: "48px",
                          backgroundColor: "#4a3aff",
                          color: "#fff",
                          fontSize: isMobile ? "16px" : "16px",
                          fontWeight: "500",
                          textTransform: "none",
                          padding: "10px 20px",
                          borderRadius: "100px",
                          borderColor: "transparent",
                          borderWidth: 1,
                          borderStyle: "solid",
                          fontFamily: "Outfit",
                          "&:hover": {
                            borderColor: "#4a3aff",
                            color: themeMode === "dark" ? "white" : "#181818",
                            backgroundColor: "transparent",
                          },
                          "&:focus": {
                            outline: "none",
                          },
                          "&.Mui-disabled": {
                            backgroundColor:
                              themeMode === "dark"
                                ? "rgba(255, 255, 255, 0.12)"
                                : "rgba(0, 0, 0, 0.12)",
                            color:
                              themeMode === "dark"
                                ? "rgba(255, 255, 255, 0.3)"
                                : "rgba(0, 0, 0, 0.3)",
                          },
                        }}
                      >
                        Crear Chatbot
                      </Button>
                    </Box>
                  </Stack>
                </motion.div>
              )}

              {isLoading && (
                <motion.div
                  key="loading"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    padding: "16px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: "600px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AnimatePresence mode="wait">
                        {!showCompletionMessage ? (
                          <motion.div
                            key={loadingMessages[messageIndex].text}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.5 }}
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: '30px'
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              {loadingMessages[messageIndex].icon}
                              <Typography
                                sx={{
                                  color: themeMode === "dark" ? "#fafafa" : "#181818",
                                  textAlign: "center",
                                  fontSize: isMobile ? "1rem" : "1.2rem",
                                  fontFamily: 'Outfit',
                                }}
                              >
                                {loadingMessages[messageIndex].text}
                              </Typography>
                            </Box>
                          </motion.div>
                        ) : (
                          <motion.div
                            key="completed"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.9 }}
                            transition={{ duration: 0.5 }}
                            style={{
                              position: "absolute",
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              height: "100vh",
                            }}
                          >
                            <motion.div
                              initial={{ scale: 0 }}
                              animate={{ scale: 1 }}
                              transition={{ type: "spring", stiffness: 260, damping: 20 }}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <CheckCircleRoundedIcon sx={{ fontSize: '68px', color: "#4caf50" }} />
                            </motion.div>
                            <Typography
                              sx={{
                                color: themeMode === "dark" ? "#fafafa" : "#181818",
                                textAlign: "center",
                                fontSize: "1.2rem",
                                fontFamily: 'Outfit',
                              }}
                            >
                              ¡Tu chatbot ha sido creado!
                            </Typography>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </Box>
                    <AnimatePresence>
                      {!showCompletionMessage && (
                        <motion.div
                          key="progress-bar"
                          initial={{ opacity: 0, scale: 0.9 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.9 }}
                          transition={{ duration: 0.5 }}
                          style={{ width: "100%" }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              backgroundColor: themeMode === "dark" ? "#434343" : "#cfcfcf",
                              borderRadius: 5,
                              overflow: "hidden",
                              mb: 2,
                            }}
                          >
                            <motion.div
                              initial={{ width: "0%" }}
                              animate={{ width: `${progress}%` }}
                              transition={{ duration: 0.5 }}
                              style={{
                                height: "20px",
                                backgroundColor: "#4a3aff",
                                borderRadius: '100px'
                              }}
                            />
                          </Box>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </Box>
                </motion.div>
              )}

              {step === 4 && chatbotCreated && (
                <VoodoBot />
              )}

            </AnimatePresence>
          </Box>
        </>
      )}
    </ThemeProvider>
  );

};

export default VoodooPage;

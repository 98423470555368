import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import cookies from 'js-cookie';
import SectionContainer from '../../global/SectionContainer';
import { ConfigContext } from '../../context/Config/ConfigContext';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../../services/firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import StoreContext from '../../context/Store/storeContext';
import { showToast } from '../../components/Toast/toast';

function Settings() {
  const { t } = useTranslation();
  const { themeMode, toggleTheme } = useContext(ConfigContext);
  const [userAuth] = useAuthState(auth);
  const { user, fetchUser } = useContext(StoreContext);

  const [language, setLanguage] = useState(user.language || 'es');
  const [currency, setCurrency] = useState(user.currency || 'MXN');
  const [country,] = useState(user.country || 'MX');

  useEffect(() => {
    if (country === 'MX') {
      setCurrency('MXN');
    } else {
      setCurrency('USD');
    }
  }, [country]);

  useEffect(() => {
    if (user.language) {
      setLanguage(user.language);
      i18next.changeLanguage(user.language);
      cookies.set('i18next', user.language, { expires: 365 });
    }
  }, [user.language]);

  const handleLanguageChange = async (event: any) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18next.changeLanguage(newLanguage);
    cookies.set('i18next', newLanguage, { expires: 365 });

    if (userAuth) {
      const userRef = doc(db, "users", userAuth.uid);
      try {
        await setDoc(userRef, { language: newLanguage }, { merge: true });
        showToast('success', t('settings.languageUpdated'), themeMode);
        fetchUser(userAuth.uid);
      } catch (error: any) { }
    }
  };

  const handleThemeChange = (event: any) => {
    const newTheme = event.target.value;
    if (newTheme !== themeMode) toggleTheme();
  };

  const selectStyles = {
    height: '40px',
    borderRadius: '10px',
    fontFamily: 'Outfit',
    border: themeMode === 'light' ? '1px solid #ced4da' : '1px solid #555',
    color: themeMode === 'dark' ? '#fff' : '#181818',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-disabled': {
      backgroundColor: themeMode === 'light' ? '#f8f9fa' : 'transparent',
      border: themeMode === 'light' ? '1px solid #ced4da' : '1px solid #555',
      color: themeMode === 'light' ? '#212529' : '#999',
      opacity: 1,
      WebkitTextFillColor: themeMode === 'light' ? '#212529' : '#999',
      '& .MuiSvgIcon-root': {
        color: themeMode === 'light' ? '#212529' : '#999',
      }
    },
    '&:hover': {
      border: themeMode === 'light' ? '1px solid #adb5bd' : '1px solid #777',
    }
  };

  const menuProps = {
    PaperProps: {
      sx: {
        bgcolor: themeMode === 'dark' ? '#181818' : '#fff',
        color: themeMode === 'dark' ? '#fff' : '#181818',
        borderRadius: '10px',
        marginTop: '5px',
        overflow: 'hidden',
        '& .MuiMenuItem-root': {
          fontFamily: 'Outfit',
          padding: '8px 16px',
          '&:hover': {
            backgroundColor: themeMode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
          },
          '&.Mui-disabled': {
            opacity: 0.5,
            cursor: 'not-allowed',
            backgroundColor: 'transparent !important',
          }
        }
      }
    }
  };

  return (
    <SectionContainer title={t('settings.title') as string}>
      <Box sx={{ backgroundColor: themeMode === 'dark' ? '#121212' : '#fafafa', pt: '30px' }}>
        <Grid container spacing={3} pb="25px" justifyContent="center">
          {/* Language Setting */}
          <Grid item xs={12} sm={6} md={4}>
            <Stack
              flexDirection="column"
              sx={{
                borderRadius: '20px',
                p: 2,
                backgroundColor: themeMode === 'dark' ? '#181818' : '#fff',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: 'Outfit',
                  color: themeMode === 'dark' ? '#fff' : '#121212',
                  fontSize: '18px'
                }}
              >
                {t('settings.language')}
              </Typography>
              <Typography variant="body2" sx={{ color: themeMode === 'dark' ? '#ccc' : '#666', mb: 1 }}>
                {t('settings.languageDescription')}
              </Typography>
              <Select
                value={language}
                onChange={handleLanguageChange}
                sx={selectStyles}
                MenuProps={menuProps}
              >
                <MenuItem value={'es'} disabled={language === 'es'}>Español</MenuItem>
                <MenuItem value={'en'} disabled={language === 'en'}>English</MenuItem>
              </Select>
            </Stack>
          </Grid>

          {/* Currency Setting */}
          <Grid item xs={12} sm={6} md={4}>
            <Stack
              flexDirection="column"
              sx={{
                borderRadius: '20px',
                p: 2,
                backgroundColor: themeMode === 'dark' ? '#181818' : '#fff',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: 'Outfit',
                  color: themeMode === 'dark' ? '#fff' : '#121212',
                  fontSize: '18px'
                }}
              >
                {t('settings.currency')}
              </Typography>
              <Typography variant="body2" sx={{ color: themeMode === 'dark' ? '#ccc' : '#666', mb: 1 }}>
                {t('settings.currencyDescription')}
              </Typography>
              <Select
                value={currency}
                disabled
                sx={selectStyles}
                MenuProps={menuProps}
              >
                <MenuItem value={'MXN'}>MXN</MenuItem>
                <MenuItem value={'USD'}>USD</MenuItem>
              </Select>
            </Stack>
          </Grid>

          {/* Theme Setting */}
          <Grid item xs={12} sm={6} md={4}>
            <Stack
              flexDirection="column"
              sx={{
                borderRadius: '20px',
                p: 2,
                backgroundColor: themeMode === 'dark' ? '#181818' : '#fff',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.03)',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: 'Outfit',
                  color: themeMode === 'dark' ? '#fff' : '#121212',
                  fontSize: '18px'
                }}
              >
                {t('settings.theme')}
              </Typography>
              <Typography variant="body2" sx={{ color: themeMode === 'dark' ? '#ccc' : '#666', mb: 1 }}>
                {t('settings.themeDescription')}
              </Typography>
              <Select
                value={themeMode}
                onChange={handleThemeChange}
                sx={selectStyles}
                MenuProps={menuProps}
              >
                <MenuItem value={'light'} disabled={themeMode === 'light'}>
                  {t('settings.theme_options.light')}
                </MenuItem>
                <MenuItem value={'dark'} disabled={themeMode === 'dark'}>
                  {t('settings.theme_options.dark')}
                </MenuItem>
              </Select>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </SectionContainer>
  );
}

export default Settings;
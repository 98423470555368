import { showToast } from '../../../components/Toast/toast';
import { fetchToOpenAI } from './OpenAIApi';

export async function processMessageToChatGPT(
  chatMessages: any,
  setMessages: Function,
  setTyping: Function,
  systemInstructions: string,
  useEmojis: boolean,
  chatbotTone: string,
  chatbotFormality: string,
  defaultLanguage: string,
  chatbotPersonality: string,
  businessType: string,
  feedbackInstructions: string,
  themeMode: string,
  chatbotImages?: any[],
  chatbotVideos?: any[],
  chatbotIntegrations?: any[],
) {


  const firstInstructions = "Vas a actuar como una persona de servicio a cliente sumamente amable y dispuesta a ofrecer información detallada sobre el negocio. Es importante que inicies preguntando el nombre del usuario.";
  const useEmojisInstruction = useEmojis ? "Es importante que utilices emojis en tus respuestas." : "No utilices emojis.";
  const chatbotToneInstruction = `El tono del chatbot es ${chatbotTone}.`;
  const chatbotFormalityInstruction = `La formalidad del chatbot es ${chatbotFormality}.`;
  const defaultLanguageInstruction = {
    es: "El idioma predeterminado del chatbot es Español. Pero si te hablan en otro idioma no dudes en cambiar de idioma y responder con ese idioma.",
    en: "El idioma predeterminado del chatbot es Inglés.  Pero si te hablan en otro idioma no dudes en cambiar de idioma y responder con ese idioma.",
  }[defaultLanguage];
  const chatbotPersonalityInstruction = `La personalidad del chatbot es ${chatbotPersonality}.`;
  const businessTypeInstruction = `El tipo de negocio para el chatbot es ${businessType}.`;

  const qualityInstructions = 'Estás aquí para ayudar con preguntas relacionadas con el negocio o servicio específico. Si recibes preguntas que no están relacionadas con el negocio, responde amablemente que estás diseñado para ayudar con temas específicos relacionados con nuestro servicio o producto. Si no tienes la respuesta a una pregunta, es preferible decir que no sabes la respuesta en lugar de proporcionar información incorrecta o especulativa. No te involucres en temas controvertidos, y si un usuario intenta guiarte hacia uno, mantén neutralidad y redirige la conversación de vuelta al foco principal del negocio. Haz las respuestas breves y precisas.';
  const fastalkSell = 'Si el cliente pregunta cómo está hecho este chatbot, menciona que está hecho por Fastalk, que se dedica a SaaS de chatbots impulsados con IA. Y dales el contacto: fastalk.app.';

  const shortMessage = 'Responde de manera breve y concisa.';


  let imageAnalysisInstruction = '';
  imageAnalysisInstruction = 'Este chatbot está habilitado para recibir imágenes como parte de las consultas. Menciona que sí puedes recibir imágenes.';

  let feedbackInstructionsPrompt = "";

  // Feedback Instructions
  if (feedbackInstructions && feedbackInstructions !== "") {
    feedbackInstructionsPrompt = `Respeta por favor las siguientes instrucciones de acuerdo al feedback del usuario: ${feedbackInstructions}.`;
  }

  const filteredChatbotImages = chatbotImages?.map((image: any) => ({
    url: image.url,
    description: image.description,
  }));

  const filteredChatbotVideos = chatbotVideos?.map((video: any) => ({
    url: video.url,
    description: video.description,
  }));

  const filteredChatbotIntegrations = chatbotIntegrations?.map((integration: any) => ({
    platform: integration.platform,
    url: integration.url,
    description: integration.description,
  }));

  // Media Instructions
  let imagesInstruction = '';
  if (filteredChatbotImages && filteredChatbotImages.length > 0) {
    imagesInstruction = `De acuerdo a esta lista de imágenes que tiene disponible el chatbot: ${JSON.stringify(filteredChatbotImages)}, por favor devuelve una etiqueta: <img src="(url de la imagen elegida)" alt="" width="100%"  style="border-radius: 15px; box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.15);" />, cuando sea necesario.`;
  }

  let videosInstruction = '';
  if (filteredChatbotVideos && filteredChatbotVideos.length > 0) {
    videosInstruction = `De acuerdo a esta lista de videos que tiene disponible el chatbot: ${JSON.stringify(filteredChatbotVideos)}, por favor devuelve una etiqueta: <iframe width="100%" height="300px" style="border-radius: 15px; box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.15);" src="(URL del video seleccionado)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>, cuando sea necesario. Nota de integración: No digas "Aquí está el enlace:" cuando pases el video, si no que menciona que "aquí te paso un video o estos videos relacionado a..."`;
  }

  // Integrations Instructions
  let platformsInstruction = '';
  if (filteredChatbotIntegrations && filteredChatbotIntegrations.length > 0) {
    platformsInstruction = `De acuerdo a esta lista de plataformas que tiene disponible el chatbot: ${JSON.stringify(filteredChatbotIntegrations)}, por favor devuelve una etiqueta: <iframe src="(URL de la plataforma seleccionada)" width="100%" height="750" style="border: 0; border-radius: 15px; overflow: hidden; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);" frameborder="0"></iframe>, cuando sea necesario. Nota: No envíes un enlace, solo el iframe, y tampoco menciones algo como "Aquí tienes el enlace para hacerlo:" ya que no es un enlace, es un iframe. Tampoco preguntes en cada mensaje estas integraciones, osea solamente hasta que el usuario diga que quiere realizar lo de las integraciones, ahí se envía el iframe. Por ejemplo no preguntes en cada mensaje si quiere agendar cita o llenar formulario.`;
  }

  const instructionsList = [
    useEmojisInstruction,
    chatbotToneInstruction,
    chatbotFormalityInstruction,
    defaultLanguageInstruction,
    chatbotPersonalityInstruction,
    businessTypeInstruction,
    qualityInstructions,
    fastalkSell,
    shortMessage,
    feedbackInstructionsPrompt,
    imagesInstruction,
    videosInstruction,
    platformsInstruction,
    imageAnalysisInstruction
  ].filter(instruction => instruction !== '').join(' ');

  const cleanedSystemInstructions = systemInstructions.replace(/^"|"$/g, '');
  const concatenatedInstructions = `${firstInstructions} ${cleanedSystemInstructions} ${instructionsList}`;

  const systemMessage = {
    role: "system",
    content: concatenatedInstructions,
  };

  let apiMessages = chatMessages.map((messageObject: any) => {
    let role = messageObject.sender === "ChatGPT" ? 'assistant' : 'user';

    let content: any[] = [{ type: "text", text: messageObject.message }];

    if (messageObject.image) {
      content.push({
        type: "image_url",
        image_url: {
          url: messageObject.image.startsWith('data:image/')
            ? `data:image/jpeg;base64,${messageObject.image.split(',')[1]}`
            : messageObject.image,
          detail: "high"
        }
      });
    }

    return { role: role, content: content };
  });

  let apiRequestBody;

  apiRequestBody = {
    "model": "gpt-4o-mini",
    "messages": [
      systemMessage,
      ...apiMessages
    ],
  };

  try {
    const data = await fetchToOpenAI(apiRequestBody);
    let messageContent = data.choices[0].message.content;

    type PreservedTag = string;

    const preservedTags: PreservedTag[] = [];

    // Primero, identificamos y preservamos las etiquetas HTML existentes
    messageContent = messageContent.replace(/<[^>]+>/g, (match: string): string => {
      preservedTags.push(match);
      return `__TAG${preservedTags.length - 1}__`;
    });

    // Ahora reemplazamos las URLs que no son parte de etiquetas
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    messageContent = messageContent.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');

    // Finalmente, restauramos las etiquetas originales sin modificarlas
    preservedTags.forEach((tag: PreservedTag, index: number): void => {
      messageContent = messageContent.replace(`__TAG${index}__`, tag);
    });


    setMessages([
      ...chatMessages,
      {
        message: messageContent,
        sender: "ChatGPT"
      }
    ]);

    setTyping(false);
  } catch (error: any) {
    const errorMessageOpenAI = defaultLanguage === 'es' ? "Error al comunicarse con OpenAI." : "Error communicating with OpenAI.";
    showToast('error', errorMessageOpenAI, themeMode);
    setTyping(false);
  }
}
/* eslint-disable max-len */
import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { IconButton, useMediaQuery, Link, CircularProgress } from '@mui/material';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import whitelogo from '../../assets/whiteLogo.png';
import blackLogo from '../../assets/mainLogo.png';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../services/firebase/firebase';
import bgImage from '../../assets/signInImage.svg';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../context/Config/ConfigContext';
import { showToast } from '../../components/Toast/toast';
import { sendPasswordResetEmail } from 'firebase/auth';

export default function ForgotPassword() {
  const { t } = useTranslation('global');
  const { themeMode, toggleTheme } = React.useContext(ConfigContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  const themee = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: 'Outfit',
            border: themeMode === "dark" ? '1px solid #272727' : '1px solid #eeeeee',
            backgroundColor: themeMode === "dark" ? "#181818" : "#fafafa",
            borderRadius: 15,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#181818" : "#fafafa",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "transparent" : 'transparent',
            },
            "& .MuiInputBase-input": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root": {
              fontFamily: 'Outfit',
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: themeMode === "dark" ? "#fafafa" : '#121212'
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff',
            },
            "& .MuiInputLabel-root.Mui-focused + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: themeMode === "dark" ? "#4a3aff" : '#4a3aff'
            },
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 15,
          },
        },
      },
    }
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    if (!email) {
      showToast('error', t('forgotPassword.enterEmail'), themeMode);
      setIsLoading(false);
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      showToast('success', t('forgotPassword.emailSent'), themeMode);
      navigate('/signin');
    } catch (error: any) {
      if (error.code === 'auth/user-not-found') {
        showToast('error', t('forgotPassword.userNotFound'), themeMode);
      } else if (error.code === 'auth/invalid-email') {
        showToast('error', t('forgotPassword.invalidEmail'), themeMode);
      } else {
        showToast('error', t('forgotPassword.errorSendingEmail'), themeMode);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const breakpointTheme = useTheme();
  const isMobile = useMediaQuery(breakpointTheme.breakpoints.down('md'));

  return (
    <Grid container sx={{ minHeight: '100vh', backgroundColor: themeMode === "dark" ? '#121212' : "#fff", }}>
      {!isMobile && (
        <Grid item xs={6} sx={{
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: themeMode === "dark" ? 0.7 : 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}>
          <Box sx={{ width: '100%', height: '100%', position: 'absolute' }} />
        </Grid>
      )}

      <IconButton onClick={() => toggleTheme()} sx={{
        "&:focus": {
          outline: "none"
        },
        position: 'fixed', top: 20, right: 20, zIndex: 1300,
      }}>
        {themeMode !== "dark" ? <ModeNightIcon sx={{ color: '#c1c1c1' }} /> : <LightModeRoundedIcon sx={{ color: '#c1c1c1' }} />}
      </IconButton>

      <Container component="main" maxWidth="xs" sx={{
        minHeight: '100vh',
        backgroundColor: themeMode === "dark" ? '#121212' : '#fff',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        py: '20px'
      }}>

        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component='img'
            alt='logo'
            src={themeMode === "dark" ? whitelogo : blackLogo}
            sx={{
              height: '50px',
              mb: '50px'
            }}
          />
          <Typography component="h1" variant="h5" sx={{ color: themeMode === "dark" ? '#fff' : '#181818', fontFamily: 'Outfit, sans-serif' }}>
            {t('forgotPassword.title')}
          </Typography>
          <Typography sx={{ color: themeMode === "dark" ? '#fff' : '#181818', fontFamily: 'Outfit, sans-serif', fontSize: '16px', fontWeight: '300' }}>
            {t('forgotPassword.enterEmail')}
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, width: '100% !important' }}>
            <Grid container spacing={2} sx={{ width: '100% !important' }}>
              <Grid item xs={12} sx={{ width: '100% !important' }}>
                <ThemeProvider theme={themee}>
                  <TextField
                    fullWidth
                    id="email"
                    placeholder={t('forgotPassword.emailLabel') as string}
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ width: '100% !important' }}
                  />
                </ThemeProvider>
              </Grid>
            </Grid>
            <Button
              variant="outlined"
              type='submit'
              disabled={isLoading}
              startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
              sx={{
                color: '#fff',
                backgroundColor: '#4a3aff',
                fontSize: '20px',
                fontWeight: '500',
                borderRadius: '100px',
                borderColor: "transparent",
                mt: '40px',
                borderWidth: 1,
                borderStyle: 'solid',
                fontFamily: 'Outfit',
                width: '100%',
                maxWidth: '200px',
                '&:hover': {
                  borderColor: '#4a3aff',
                  backgroundColor: 'transparent',
                  color: themeMode === "dark" ? 'white' : '#181818',
                  borderWidth: 1,
                  borderStyle: 'solid',
                },
                '&:focus': {
                  outline: 'none',
                },
                '&.Mui-disabled': {
                  backgroundColor: themeMode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                  color: themeMode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
                },
                textTransform: 'none'
              }}
            >
              {isLoading ? t('forgotPassword.sending') : t('forgotPassword.sendEmailButton')}
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography sx={{ fontFamily: 'Outfit, sans-serif', color: themeMode === "dark" ? '#fff' : '#181818', mt: 3 }}>
                  {t('forgotPassword.rememberedPassword')}{' '}
                  <Link
                    onClick={() => { navigate('/signin') }}
                    style={{ fontWeight: 'bold', textDecoration: 'none', cursor: 'pointer' }}
                  >
                    {t('forgotPassword.goBackToSignIn')}
                  </Link>.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Grid>
  );
}

import { useContext } from "react";
import { Box, Button, IconButton, InputBase, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import whiteLogo from "../assets/whiteLogo.png";
import blackLogo from "../assets/mainLogo.png";
// import { ConfigContext } from "../context/ConfigContext";
import AccountMenu from "./AccountMenu";
import StoreContext from "../context/Store/storeContext";
import { auth } from "../services/firebase/firebase";
import { signOut } from "firebase/auth";
// import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../theme";
import { ConfigContext } from "../context/Config/ConfigContext";
import { useTranslation } from "react-i18next";
import { showToast } from "../components/Toast/toast";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';


const Topbar = ({ setIsSidebarOpenFromMobile }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const colors = tokens(theme.palette.mode);

  // Obtener el logo del contexto
  // const { logo } = useContext(ConfigContext);
  const { user } = useContext(StoreContext);
  const navigate = useNavigate();

  const { resetConfigContextFromSignUp } = useContext(ConfigContext);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('chatbotConfig');
      resetConfigContextFromSignUp();
      navigate('/signin');
    } catch (error) {
      if (error instanceof Error) {
        showToast('error', t('toast.errorLoggingOut'), themeMode);
      } else {
        throw error;
      }
    }
  };

  const { themeMode } = useContext(ConfigContext)

  const { t } = useTranslation('global')

  function mapSubscriptionTypeToPlanName(subscriptionType: any) {

    switch (subscriptionType) {
      case "free":
        return t('clientSubscription.plans.free.name');

      // Agrupa todos los planes mensuales y anuales de Basic bajo el mismo nombre
      case "basicMonthlyMXN":
      case "basicAnnualMXN":
      case "basicMonthlyUSD":
      case "basicAnnualUSD":
        return t('clientSubscription.plans.basic.name');

      // Agrupa todos los planes mensuales y anuales de Premium bajo el mismo nombre
      case "premiumMonthlyMXN":
      case "premiumAnnualMXN":
      case "premiumMonthlyUSD":
      case "premiumAnnualUSD":
        return t('clientSubscription.plans.premium.name');

      // Agrupa todos los planes mensuales y anuales de Business bajo el mismo nombre
      case "enterpriseMonthlyMXN":
      case "enterpriseAnnualMXN":
      case "enterpriseMonthlyUSD":
      case "enterpriseAnnualUSD":
        return t('clientSubscription.plans.business.name');

      default:
        return t('clientSubscription.plans.free.name');
    }
  }

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" p={1}
      sx={{
        backgroundColor: themeMode === "dark" ? '#121212' : '#fff',
        borderBottom: themeMode === "dark" ? '1px solid #181818' : '1px solid #fafafa'
      }}
    >
      {
        isMobile && (
          <Stack flexDirection="row" justifyContent="start" alignItems="center">
            <IconButton
              sx={{ '&:focus': { outline: 'none' }, paddingTop: 0 }}
              onClick={() => {
                setIsSidebarOpenFromMobile(true)
              }}
            >
              <MenuRoundedIcon sx={{ fontSize: '26px', color: themeMode === "dark" ? "white" : 'black' }} />
            </IconButton>
            <img
              src={themeMode === "dark" ? whiteLogo : blackLogo}
              alt="logo"
              height={'30px'}
              style={{ transition: 'height 0.25s ease', marginLeft: '10px' }}
            />
          </Stack>
        )
      }


      {/* SEARCH BAR */}
      {
        !isMobile && (
          <Box
            display="flex"
            sx={{
              backgroundColor: themeMode === "dark" ? '#181818' : '#fafafa',
              borderRadius: '10px',
              ml: '30px',
              width: '250px'
            }}
          >
            <InputBase
              sx={{
                fontFamily: 'Outfit',
                ml: 2,
                flex: 1,
                color: themeMode === "dark" ? '#fafafa' : '#181818',
                '&::placeholder': {
                  fontFamily: 'Outfit',
                  color: colors.grey[900],
                }
              }}
              placeholder="Buscar"
            />
            <IconButton type="button" sx={{ p: 1, '&:focus': { outline: 'none' } }}>
              <SearchIcon sx={{ color: themeMode === "dark" ? '#fafafa' : '#181818' }} />
            </IconButton>
          </Box>
        )
      }

      {/* ICONS */}
      <Stack flexDirection="row" justifyContent="center" alignItems="center" gap={0} >
        {/* <IconButton sx={{ '&:focus': { outline: 'none' } }}>
          <NotificationsIcon sx={{ color: themeMode === "dark" ? '#fafafa' : '#cfcfcf' }} />
        </IconButton> */}
        <Button
          sx={{
            textTransform: 'none',
            ':focus': { outline: 'none' },
            borderRadius: '100px',
            color: themeMode === "dark" ? '#fafafa' : '#181818',
            p: '4px 16px',
            // border: '1px solid #4a3aff',
            height: '30px',
            '&:hover': {
              backgroundColor: themeMode === 'dark' ? '#181818' : '#fafafa'
            },

          }}
          onClick={() => navigate('/subscription')}
        >
          <Typography
            variant="subtitle1"
            sx={{
              backgroundColor: 'transparent',
              color: themeMode === "dark" ? '#fafafa' : '#fff',
              fontSize: '16px',
              borderRadius: '100px',
              height: '30px',
              fontWeight: '700',
              background: `linear-gradient(45deg, #4a3aff, ${themeMode === "dark" ? "#fafafa" : "#c1c1c1"})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              MozBackgroundClip: 'text',
              MozTextFillColor: 'transparent',
              display: 'inline',

            }}
          >
            {
              (user.subscriptionStatus === "canceled") ? t('clientSubscription.planDeactivated') : (`${mapSubscriptionTypeToPlanName(user.subscriptionType)}`)
            }
          </Typography>
        </Button>
        <AccountMenu onSignOut={handleSignOut} user={user} />
      </Stack>
    </Box>
  );
};

export default Topbar;
